import React from 'react'
import { applePay, googlePay, stripe, paypal, amazonpay, bitcoin} from '../../../utilities/images'

const ThirdpartyApi = () => {
  return (
    <div className='thirdpartyBox_'>
      <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-12">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>3rd party API</span> </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='thirdpartyInfo_'>
          <div className='row'>
            <div className='col-md-3 customPadding_'>
              <div className='thirdpartyInner_'>
                <img className='thirdpartyIcon' src={applePay} />
                <div className='iconContent'>
                  <h5 className='subheadText'>Apple Pay</h5>
                  <p className=' callDate_'>Apple pay API is an API company that hosts a number of different APIs. They have APIs to handle ....</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 customPadding_'>
              <div className='thirdpartyInner_'>
                <img className='thirdpartyIcon' src={googlePay} />
                <div className='iconContent'>
                  <h5 className='subheadText'>Google Pay</h5>
                  <p className=' callDate_'>Apple pay API is an API company that hosts a number of different APIs. They have APIs to handle ....</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 customPadding_'>
              <div className='thirdpartyInner_'>
                <img className='thirdpartyIcon' src={stripe} />
                <div className='iconContent'>
                  <h5 className='subheadText'>Stripe</h5>
                  <p className=' callDate_'>Apple pay API is an API company that hosts a number of different APIs. They have APIs to handle ....</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 customPadding_'>
              <div className='thirdpartyInner_'>
                <img className='thirdpartyIcon' src={paypal} />
                <div className='iconContent'>
                  <h5 className='subheadText'>Paypal</h5>
                  <p className=' callDate_'>Apple pay API is an API company that hosts a number of different APIs. They have APIs to handle ....</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 customPadding_'>
              <div className='thirdpartyInner_'>
                <img className='thirdpartyIcon' src={amazonpay} />
                <div className='iconContent'>
                  <h5 className='subheadText'>Amazon Pay</h5>
                  <p className=' callDate_'>Apple pay API is an API company that hosts a number of different APIs. They have APIs to handle ....</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 customPadding_'>
              <div className='thirdpartyInner_'>
                <img className='thirdpartyIcon' src={bitcoin} />
                <div className='iconContent'>
                  <h5 className='subheadText'>BitCoin</h5>
                  <p className=' callDate_'>Apple pay API is an API company that hosts a number of different APIs. They have APIs to handle ....</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ThirdpartyApi