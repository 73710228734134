import React, { useState } from 'react'
import { resetmodalimg } from "../../../utilities/images";

const ScheduleModal = (props) => {
  return (
    <div className='noHeaderModal'>

      <button className="closeAction"><i className="las la-times" onClick={props.close}></i></button>

      <div className='noHeaderModalcontent'>
        <img src={resetmodalimg} />
        <h4>A link Has sent to your email</h4>
        <p>Please login back to your email inbox and set your new password</p>

      </div>
    </div>
  )
}

export default ScheduleModal