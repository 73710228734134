import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { verifidecart, sila, Merchant, redpdf, Viewimg, downloadimg, Verfidsky } from '../../../../utilities/images'

const DenieddetailKyb = () => {
  const history = useHistory();

  document.title = "VerifiedDetailkyb";
  return (
    <>
      <div className='topHeader_ mb-3'>
        <div className='row  align-items-center'>
          <div className='col-md-6'>
            <h2 className='anotherHead' onClick={() => { history.push("/verification/applicationDetailkyc") }}><i class="fal fa-angle-left"></i> Applicant details </h2>
          </div>
        </div>
      </div>
      <div className='applicantDetails businessDetail'>
        <div className='col-12'>
            <div className='chartsOuter'>
                <div className='verifydetailHead mb-3'>
                        <h5 className='deatilsHead'>Business Details</h5>
                        <div className='applicationNum'>
                            <img src={Merchant} />
                            <h5 className='innerSubtext'>260 101 480 0083</h5>
                        </div>
                </div>
              <div className='personalDetails'>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Legal business name</span>
                  <strong className='innerContent'>Triva Travels </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business Type</span>
                  <strong className='innerContent'>Agency</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business phone number</span>
                  <strong className='innerContent'>(319) 424-4476</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business EIN</span>
                  <strong className='innerContent'>****-**-4476</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business email address</span>
                  <strong className='innerContent'>trivatravels@triva.com </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Date of incorporation</span>
                  <strong className='innerContent'>07/08/2005 </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business website</span>
                  <strong className='innerContent'>www.triva.com </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business address</span>
                  <strong className='innerContent'>1752 Lincoln Drive Fairfield (Adams), PA 17320</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Registration state </span>
                  <strong className='innerContent'>PA</strong>
                  <img src={Verfidsky} />
                </h5>
              </div>
            </div>
        </div>
        <div className='row'>
          <div className='col-sm-7 col-md-7'>
            <div className='chartsOuter mt-3'>
              <h5 className='deatilsHead'>Payment Details</h5>
              <div className='paymentDetails'>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Bank Account</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Credit/Debit</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Apple pay</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Paypal</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-md-5'>
            <div className='chartsOuter kybdocument mt-3'>
            <div className='verifydetailHead mb-3'>
                    <h5 className='deatilsHead'>Uploaded Documents</h5>
                     <img src={Verfidsky} />
              </div>
              <div className='uploadDocument mb-3'>
                <div className='uploadContent'>
                  <h6>Trade License </h6>
                  <div className='docFileupload'>
                    <img src={redpdf} />
                    <span>Apparel_catelog_Childer.pdf</span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
              <div className='uploadDocument mb-2'>
                <div className='uploadContent'>
                  <h6>Tax Doc</h6>
                  <div className='docFileupload'>
                    <img src={redpdf} />
                    <span>Apparel_catelog_Childer.pdf</span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
              <div className='uploadDocument mb-2'>
                <div className='uploadContent'>
                  <h6>Proof of address</h6>
                  <div className='docFileupload'>
                    <img src={redpdf} />
                    <span>Apparel_catelog_Childer.pdf</span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
            </div>
            <div className='chartsOuter mt-3'>
              <div className='drivepersonalInfo'>
                <h5 className='deatilsHead'>Device Info</h5>
                <div className='row'>
                  <div className="col-md-6"><p className='deviceHead'>Device</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>iPhone</p></div>
                  <div className="col-md-6"><p className='deviceHead'>IP Address (IPv4):</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>111.174.210.87</p></div>
                  <div className="col-md-6"><p className='deviceHead'>MAC Address:</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>35:06:2A:4F:12:0C</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default DenieddetailKyb;