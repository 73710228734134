import React from 'react'
import { bankImg, marchantpro, merchantActive, flagstate, chatIcon, sila, verifiednoBg, Verfidsky, Merchant, docupload, Viewimg, downloadimg, } from '../../../../utilities/images'
import { Link, useHistory } from 'react-router-dom'
const Activemarchant = () => {
  const history = useHistory()
  return (
    <>
    <div className='marchatUser'>
        <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-12">
              <div className='anotherHeader'>
                <i class="las la-angle-left" onClick={() => { history.push("/userList/activeUsers") }} ></i>
                <h2 class="innerSubheadNormal">Back</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
            <div className='row'>
                <div className='col-md-12'>
                    <button className='valuesTimeswallet active'>Company Profile</button>
                    <button className='valuesTimeswallet'>Token Usages History</button>
                    <button className='valuesTimeswallet'>Token Trades History</button>
                </div>
            </div>
        </div>
    </div>
    <div className='chartsOuter mt-4'>
      <div className='merchantactive'>
          <div className="row">
            <div className="col-md-8">
              <div className='CustomerOnlinestatus_ afterBorder'>
                        <div className="buyerDeatils">
                          <div className="userProfileinfo_">
                              <img className="marchantPro" src={marchantpro} alt="profileimg" />
                                  <div className="userProfileinforight_">
                                      <h3 className='mainHeading'>BBQ</h3>
                                      <p className='contentextLight'>
                                        <img src={merchantActive} /> 
                                        <span>260 101 480 0083</span> 
                                      </p>
                                      <p className='contentextLight'>
                                        <img src={flagstate} /> 
                                        <span> United States of America</span> 
                                      </p>
                                    <div className='marchantBtn'>
                                        <Link to="#" className="chatBtn"><img src={chatIcon} /></Link>
                                        <Link to="#" className="disableAccount">Disable  Account</Link>
                                    </div>
                                  </div>
                              </div>
                        </div>
                        <div className='activebadge'>
                        <span className='dotActive'></span> <small> Active</small>
                        </div>
                  </div>
             </div>
          

            <div className="col-md-4">
              <div className="CustomerOnlinestatus_">
                <p className='blueboldHead'>
                <strong>Registered</strong><span className='lightsubHead'>3 locations</span>
                </p>
                <p className='blueboldHead'>
                <strong>Active Since</strong> <span className='lightsubHead'>265 days</span>
                </p>
                <p className='blueboldHead'>
                <strong>Tokens</strong><span className='lightsubHead'>JBR 259,265</span>
                </p>
                <p className='blueboldHead'>
                <strong>Transactions</strong><span className='lightsubHead'>352 times</span>
                </p>
              </div>
            </div>
          </div>
          <div className='verifiedMarchant mt-4'>
            <img src={sila} />
            <img src={verifiednoBg} />
          </div>

          <div className='applicantDetails businessDetail mt-3'>
          <div className='col-12'>
            <div className='chartsOuter'>
                <div className='verifydetailHead mb-3'>
                        <h5 className='deatilsHead'>Business Details</h5>
                </div>
              <div className='personalDetails'>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Legal business name</span>
                  <strong className='innerContent'>Triva Travels </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business Type</span>
                  <strong className='innerContent'>Agency</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business phone number</span>
                  <strong className='innerContent'>(319) 424-4476</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business EIN</span>
                  <strong className='innerContent'>****-**-4476</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business email address</span>
                  <strong className='innerContent'>trivatravels@triva.com </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Date of incorporation</span>
                  <strong className='innerContent'>07/08/2005 </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business website</span>
                  <strong className='innerContent'>www.triva.com </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Business address</span>
                  <strong className='innerContent'>1752 Lincoln Drive Fairfield (Adams), PA 17320</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Registration state </span>
                  <strong className='innerContent'>PA</strong>
                  <img src={Verfidsky} />
                </h5>
              </div>
            </div>
        </div>
        <div className='row'>
          <div className='col-sm-7 col-md-7'>
            <div className='chartsOuter'>
              <h5 className='deatilsHead'>Payment Details</h5>
              <div className='paymentDetails'>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Bank Account</span>
                  <img className="bankImg_"src={bankImg}/>
                  <small className='boldsubSubhead'>........ 1612 2786</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Credit/Debit</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Apple pay</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Paypal</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-md-5'>
            <div className='chartsOuter kybdocument'>
            <div className='verifydetailHead mb-3'>
                    <h5 className='deatilsHead'>Uploaded Documents</h5>
                     <img src={Verfidsky} />
              </div>
              <div className='uploadDocument mb-3'>
                <div className='uploadContent'>
                  <h6>Trade License </h6>
                  <div className='docFileupload'>
                    <img src={docupload} />
                    <span>Apparel_catelog_Childer.pdf</span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
              <div className='uploadDocument mb-2'>
                <div className='uploadContent'>
                  <h6>Tax Doc</h6>
                  <div className='docFileupload'>
                    <img src={docupload} />
                    <span>Apparel_catelog_Childer.pdf</span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
              <div className='uploadDocument mb-2'>
                <div className='uploadContent'>
                  <h6>Proof of address</h6>
                  <div className='docFileupload'>
                    <img src={docupload} />
                    <span>Apparel_catelog_Childer.pdf</span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
            </div>
            <div className='chartsOuter kybdocument mt-3'>
              <div className='drivepersonalInfo'>
                <h5 className='deatilsHead'>Device Info</h5>
                <div className='row'>
                  <div className="col-md-6"><p className='deviceHead'>Device</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>iPhone</p></div>
                  <div className="col-md-6"><p className='deviceHead'>IP Address (IPv4):</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>111.174.210.87</p></div>
                  <div className="col-md-6"><p className='deviceHead'>MAC Address:</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>35:06:2A:4F:12:0C</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    
    </>
  )
}

export default Activemarchant