import React from 'react'
import { shareMap } from '../../../../utilities/images'

const HeaderStatistics = (props) => {
    return (
        <div className='col-sm-6 col-md-3 col-lg-3 '>
            <div className='percentageContainer'>
                <div className='percentageValues'>
                    <h3 className='heading_ '>{props.value}</h3>
                    <p className='textInner'>{props.heading}</p>
                </div>
                <div className='percentageRating'>
                    <img src={shareMap} className="" alt='ShareMap' />
                    <p className='percentageRate'>{props.percentage}%</p>
                </div>
            </div>
        </div>
    )
}

export default HeaderStatistics