import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import CustomModal from "../../components/shared/CustomModal";
import TwoAuthentication from "./twoAuthenticationModal";
const WalletauthenModal = (props) => {
  const [key, setKey] = useState(Math.random());

  const history = useHistory();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  return (
    <>
      <div className="walletauthModal">
        <div className="walletauthHead">
          {" "}
          <h4>Enable Two-Steps Security</h4>
          <button class="closeAction">
            <i class="las la-times" onClick={props.close}></i>
          </button>
        </div>
        <div className="walletauthContent">
          <h3 className="textHeading">Two factor authenticarion is not enabled yet.</h3>
          <p className="textParagh">
            Two factor authenticarion addds an additional layer of security to
            your account by requiring more than just a password to sign in.
          </p>
        </div>
        <div className="walletauthBottom">
          <button type="button"
            className="NextBtn"
            onClick={() => {
              setModalDetail({ show: true, flag: "Twoauthentication" });
              setKey(Math.random());
            }}
          >
            Enable two-factor authenticarion
          </button>
          <Link to="#" className="learnMore">
            Learn More
          </Link>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "Twoauthentication"
            ? "twoauthenticationModal"
            : ""
        }
        ids={
          modalDetail.flag === "Twoauthentication"
            ? "twoauthenticationModal"
            : ""
        }
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={
          modalDetail.flag === "Twoauthentication" ? 
          (
            <TwoAuthentication close={() => handleOnCloseModal()} /> 
          ) 
          
          : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default WalletauthenModal;
