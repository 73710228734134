import React from "react";
import { useHistory } from "react-router-dom";
import { protectionimg } from "../../../utilities/images";
import { useState } from "react";
import CustomModal from "../../components/shared/CustomModal";
import WalletauthenModal from "./WalletauthenModal";

const WalletLogin = () => {
  const [key, setKey] = useState(Math.random());

  const history = useHistory();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  return (
    <>
      <div className="commonWallet">
        <div className="container">
          <div className="row wallethorizontalform">
            <div className="col-lg-7">
              <div className="walletForm">
                <div className="wallethead">
                  <img src={protectionimg} />
                  <h5>You are invited as</h5>
                  <h3>“Wallet-Admin” </h3>
                </div>
                <form  className="form_horizontal">
                  <h4>Create your new password</h4>
                  <div className="mb-1 form-group">
                    <label class="form-label">New Password </label>
                    <input
                      className="form-control id_password"
                      name="password"
                      type={"password"}
                    />
                    <span class="toggle_password_ info_icon">
                      <span class="hide-icon togglePassword" id=""></span>
                    </span>
                  </div>
                  <div className="mb-1 form-group">
                    <label class="form-label">Re-enter New Password</label>
                    <input
                      className="form-control id_password"
                      name="password"
                      type={"password"}
                    />
                    <span class="toggle_password_ info_icon">
                      <span class="hide-icon togglePassword" id=""></span>
                    </span>
                  </div>
                  <div className="buttonBott d-grid gap-2 col-12 mx-auto">
                    <button
                      className="NextBtn mt-4"
                      type="button"
                      onClick={() => {
                        setModalDetail({ show: true, flag: "Walletauthen" });
                        setKey(Math.random());
                      }}
                    >
                      <span>
                        Next <i class="fa-solid fa-arrow-right-long"></i>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="copyRight">
              <p>All Rights Reserved by JOBR LLC | 2022</p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "Walletauthen" ? "walletauthenModal" : ""}
        ids={modalDetail.flag === "Walletauthen" ? "walletauthenModal" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={
          modalDetail.flag === "Walletauthen" ? (
            <WalletauthenModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default WalletLogin;
