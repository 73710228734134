import moment from 'moment-timezone';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { calendar } from '../../../../utilities/images';
import products from "../../../db/category.json";
const LogHistory = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        {
          name: "Log in date",
          selector: row => {
          return (
                <>
                  <span className='textParagh '>05 May, 2022</span>
                </>
              );
          },
          width: "130px", 
        },
        { name: 'Log in time',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>10:05:32 pm</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'logout date',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>05 May, 2022</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'logout time',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>05:12:32 pm</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'Duration',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>08h 07min 00sec</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'Media',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Chrome</span>
                </>
              );
          },
          width: "220px",
        },
        // {
        //   selector: row => row.types,
        //   name: 'Type'
        // },
        // {
        //   selector: row => row.fees,
        //   name: 'Fees'
        // },
    ];
    
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='logHistory'>
         <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters'>
                <div className="calenderIcon">
                  <img src={calendar} alt="calender" />
                  <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                  dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
                </div>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Country
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      State
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Id type
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
          </div>
        </div>
         <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
    </div>
  )
}

export default LogHistory