import React from 'react'
import {
  certificateImg
} from "../../../../utilities/images";

const DetailkybModal = (props) => {
 
  return (
    <>
   <div className="commonModal">
      <div className="modalContent">
     <img className='certificateImage' src={certificateImg} />
      </div>
    </div>
    </>
  )
}

export default DetailkybModal