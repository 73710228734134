import React from 'react'
import { useHistory } from 'react-router-dom'
import { detailuser, phoneBlue, emailBlue, locationBlue, Sendfill} from '../../../../utilities/images'
const AssignemailDetail = () => {
    const history = useHistory()
  return (
    <>
    <div className='suportticketDetails'>
        <div className='topHeader_'>
            <div class="topStickyHeader_ mb-3">
                <div class="row  align-items-center">
                    <div class="col-md-12">
                        <div className='anotherHeader'>
                        <i class="las la-angle-left" onClick={() => { history.push("/support/assignedEmail") }}></i>
                        <h2 class="innerSubheadNormal">Ticket id #25649895</h2>
                        <span class="bundleBtn">Assigned</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='suportticketDetails_'>
        <div className='row'>
            <div className='col-lg-9'>
                <div className='informationcontentInner'>
                    <div className='detailleftCommon'>
                        <div className='detailsTicket_'>
                            <div className='detailsTicketinfo_'>
                                <img src={detailuser} />
                                <div className='nameDetail'>
                                    <h5 className='contentSubtextbold'>Florecilla Jonny</h5>
                                    <p className='tableHeadsmall'>Manufacturer</p>
                                </div>
                            </div>
                            <p className='descriptionPara_ mt-2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultrices suscipit vehicula. Phasellus feugiat, velit nec volutpat pretium, massa metus blandit sem, et auctor est nisi at neque. Phasellus eget velit efficitur, posuere erat sit amet, mollis ante. Nunc eleifend nisl commodo facilisis dictum. Sed sodales iaculis euismod. Etiam auctor lectus sed tortor pulvinar aliquam. Etiam in ipsum odio. Duis ut orci gravida mauris semper suscipit.
                            </p>
                            <span className='headsubHeading__ d-flex justify-content-end'>20 May, 2022   | 08:09am</span>
                        </div>
                        <div className='detailsTicket_'>
                            <div className='detailsTicketinfo_'>
                                <img src={detailuser} />
                                <div className='nameDetail'>
                                    <h5 className='contentSubtextbold'>Florecilla Jonny</h5>
                                    <p className='tableHeadsmall'>Manufacturer</p>
                                </div>
                            </div>
                            <p className='descriptionPara_ mt-2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultrices suscipit vehicula. Phasellus feugiat, velit nec volutpat pretium, massa metus blandit sem, et auctor est nisi at neque. Phasellus eget velit efficitur, posuere erat sit amet, mollis ante. Nunc eleifend nisl commodo facilisis dictum. Sed sodales iaculis euismod. Etiam auctor lectus sed tortor pulvinar aliquam. Etiam in ipsum odio. Duis ut orci gravida mauris semper suscipit.
                            </p>
                            <span>----------</span>
                            <p className='descriptionPara_ mb-3'>
                            Regards,
                        </p>
                            <p className='descriptionPara_ m-0'>
                            Satomi D.
                                </p>
                                <p className='descriptionPara_  m-0'>
                            Jobr.com Support Team              
                                </p>
                            <span className='headsubHeading__ d-flex justify-content-end'>20 May, 2022   | 08:09am</span>
                        </div>
                    </div>
                    <div className='chatInput_'>
                        <i class="fal fa-ellipsis-v icon_" aria-hidden="true"></i>
                        <input type="text" className=' chatbox' placeholder='Type here'/>
                        <button type="submit" className='sendBtn'>Send <img src={Sendfill} /></button>
                    </div>
                </div>
            </div>
            <div className='col-lg-3'>
                <div className='detailsTicketRight_'>
                    <div className='detailsTicketinfo_ mb-2'>
                            <img src={detailuser} />
                            <div className='nameDetail'>
                                <h5 className='contentSubtextbold'>Florecilla Jonny</h5>
                                <p className='tableHeadsmall'>Manufacturer</p>
                            </div>
                        </div>           
                        <div className="detailsTicketinfoInner_">
                            <p className='contentextLight'>
                            <img src={phoneBlue} /> 
                            <span>4703-515-1193</span> 
                            </p>
                            <p className='contentextLight'>
                            <img src={emailBlue} /> 
                            <span> vidal.webe2@gmail.com</span> 
                            </p>
                            <p className='contentextLight'>
                            <img src={locationBlue} /> 
                            <span> 4318 Daffodil Lane, Savage, Virginia(VA), 20763</span> 
                            </p>
                        </div>
                        <div className='totalTickets_ mt-3'>
                            <h5 className='subInnersubhead'>Total Tickets: 16</h5>
                            <div className='totalticketBottom'>
                                 <p className='smallHeading'>Solved: <strong>9</strong></p>
                                 <p className='smallHeading'>Cancelled: <strong>9</strong></p>
                            </div>
                            <div className='totalticketBottom'>
                                 <p className='smallHeading'>Open: <strong>9</strong></p>
                                 <p className='smallHeading'>Unrespond: <strong>9</strong></p>
                            </div>
                        </div>
                        </div>
                        <div className='asignAgent_ mt-3'>
                            <h5 className='agentHeading'>Assigned agent:</h5>
                            <div className='detailsTicketinfo_ mb-2'>
                            <img src={detailuser} />
                            <div className='nameDetail'>
                                <h5 className='contentSubtextbold'>Satomi D.</h5>
                                <p className='tableHeadsmall'>13 Jun, 2022  |   12:25p</p>
                            </div>
                            </div>
                            <h5 className='agentHeading mt-4' >Also on this ticket:</h5>
                            <div className='detailsTicketinfo_ mb-2'>
                            <img src={detailuser} />
                            <div className='nameDetail'>
                                <h5 className='contentSubtextbold'>Satomi D.</h5>
                            </div>
                        </div> 
                        <div className='detailsTicketinfo_ mb-2'>
                            <img src={detailuser} />
                            <div className='nameDetail'>
                                <h5 className='contentSubtextbold'>Satomi D.</h5>
                            </div>
                        </div> 
                        </div>
                   
             </div>
            </div>
        </div>
   

       
    </>
  )
}

export default AssignemailDetail