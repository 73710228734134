import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user } from '../../../../utilities/images';
const ResolvedChat = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
      {
        name: '#',
        selector: row => row.id,
        width: "50px" 
      },
      {
        name: "Name",
        selector: row => {
        return (
              <>
                <div className='d-flex align-items-center'>
                  <img className='userInnerImg' src={user} />
                  <div className='ms-2'>
                    <span className='contentSubtext_ '>{row.name}</span>
                    <p className='mb-0'>
                      <img src={shield} className='img-fluid me-2'/>
                      <span className='textInner'>260 101 480 0083 </span>
                    </p>
                  </div>
                </div>
              </>
            );
        },
        width: "300px", 
      },
      { name: 'Date',
      selector: row => {
        return (
              <>
                <div className='ms-2'>
                    <span className='contentSubtext_'>{row.date}</span>
                    <p className='textInner mt-2'>9:00:23a</p>
                </div>
              </>
            );
        },
        width: "220px",
      },
      {
        selector: row => row.value,
        name: 'Ticket id'
      },
      {
        selector: row => row.subject,
        name: 'Subject'
      },
      {
        selector: row => row.amount,
        name: 'User Type'
      },
      {
        selector: row => row.responsible,
        name: 'Responsible'
      },
      {
        name: 'Status',
        selector: row => {
          return (
                <>
                  <div className='ms-2 mt-2 tableBtn__ text-center'>
                      <Link to='' className='updatePriceBtn'>Assigned</Link>
                      <i class="fal fa-chevron-right ms-3" aria-hidden="true" onClick={() => { history.push("/support/resolvechatDetail") }}></i>
                  </div>
                </>
              );
          },
          width: "170px"
      },
    
    ];
  
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='suportTicket'>
       <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-12">
                <h2 class="mainHeading">Support /<span className='innerSubtext'> Tickets</span></h2>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
            <div className='row'>
            <div className='col-md-12'>
                    <button className='valuesTimeswallet'>New Chats (05)</button>
                    <button className='valuesTimeswallet'>Pending Chats (13)</button>
                    <button className='valuesTimeswallet'>Assigned Chats (13)</button>
                    <button className='valuesTimeswallet active'>Resolved Chats</button>
                </div>
            </div>
        </div>
        <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters'>
                <div className="calenderIcon">
                  <img src={calendar} alt="calender" />
                  <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                  dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
                </div>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Country
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      State
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Id type
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
          </div>
        </div>
        <DataTable
          pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
    </div>
  )
}

export default ResolvedChat
