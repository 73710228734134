import React from 'react'
import { downlight } from '../../../utilities/images'
import products from "../../db/category.json"
import DataTable from 'react-data-table-component'
import { Link, useHistory } from 'react-router-dom'
import { graph2, shareMap, shield, user, user1 } from '../../../utilities/images'
// import { Chart } from "react-google-charts";


const SupportOverview = () => {

  const history = useHistory()
  const columns1 = [
    {
      name: '#',
      selector: row => row.id,
      width: "50px"
    },
    {
      name: 'Date',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='contentSubtext_'>{row.date}</span>
              <p className='textInner mt-2'>9:00:23a</p>
            </div>
          </>
        );
      },
      width: "220px",
    },
    {
      selector: row => row.value,
      name: 'Ticket id'
    },
    {
      selector: row => row.amount,
      name: 'Department'
    },
    {
      selector: row => row.amount2,
      name: 'Subject'
    },
    {
      selector: row => row.types,
      name: 'Responsible'
    },
    {
      name: 'Status',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='personalinfoHead mt-21234'>Resoved | agent name</span>
              <p className='tablesubHead mt-2'>Sep 22, 2022 | 9:30 am</p>
            </div>
          </>
        );
      },
      width: "170px"
    },
    {
      name: '',
      selector: row => {
        return (
          <>
            <i class="fal fa-chevron-right " ></i>
          </>
        );
      },
      width: "50px",
    },
  ];
  const feeOptions = {
    // title: "Fees earning analysis",
    // isStacked: true,
    height: 300,
    width: 450,
    series: [{ "color": "#6C63F0" }, { "color": "#FB466C" }],
    intervals: { "style": "area" },
    curveType: "function",
    legend: { position: "top", maxLines: 7 },
    vAxis: { minValue: 0 },
  };
  const feeData = [
    ["", "Solved", 'Unsolved'],
    ['Mon', 42, 36],
    ['Tue', 20, 34],
    ['Wed', 54, 67],
    ['Thu', 35, 45],
    ['Fri', 44, 76],
    ['Sat', 77, 64],
    ['Sun', 66, 87]
  ];
  const tokenOptions = {
    // title: "Token Trades analysis",
    // isStacked: true,
    height: 400,
    width: 500,
    series: [{ "color": "#6C63F0" }, { "color": "#FB466C" }],
    intervals: { "style": "area", "color": "#FB466C" },
    curveType: "function",
    legend: { position: "top", maxLines: 7 },
    vAxis: { minValue: 0 },
};

const tokenData = [
    ["", "Respond", 'Cutoff '],
    ['Mon', 42, 36],
    ['Tue', 20, 34],
    ['Wed', 54, 67],
    ['Thu', 35, 45],
    ['Fri', 44, 76],
    ['Sat', 77, 64],
    ['Sun', 66, 87]
];

  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }
  return (
    <div className='supportMain'>
      <div className='topHeader_'>
        <div class="topStickyHeader_ mb-3">
          <div class="row  align-items-center">
            <div class="col-md-12">
              <h2 class="mainHeading">Support</h2>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 text-end'>
          <div class="dropdown weekDropdown">
            <button class="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              This week <img src={downlight} alt="img" />
            </button>
            <ul class="dropdown-menu weekDropdownMenus">
              <li><a class="dropdown-item">This week</a></li>
              <li><a class="dropdown-item">Last Week</a></li>
              <li><a class="dropdown-item">This Month</a></li>
              <li><a class="dropdown-item">This Quarter </a></li>
              <li><a class="dropdown-item">This Year</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='respondAnalysis'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-6 mt-3 mb-3'>
            <div className='chartsOuter'>
              <h3 class="textInnerHead">Tickets Respond analysis </h3>
              <div className='graphContainer'>
                {/* <Chart
                  chartType="Bar"
                  data={feeData}
                  options={feeOptions}
                  legendToggle
                /> */}
                <img src='https://www.pngall.com/wp-content/uploads/12/Graph-PNG-Images-HD.png' className='graphImg' alt='Graph'/>
              </div>
              <div className='respondInformation mt-3'>
                <div className='respondInfoinner_'>
                  <h3 className='heading_ '>2.1k</h3>
                  <p className='textInner'>New Tickets</p>
                </div>
                <div className='respondInfoinner_'>
                  <h3 className='heading_ '>2.1k</h3>
                  <p className='textInner'>Assigned</p>
                </div>
                <div className='respondInfoinner_'>
                  <h3 className='heading_ '>2.1k</h3>
                  <p className='textInner'>Unassigned</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 mt-3 mb-3'>
            <div className='chartsOuter'>
              <h3 class="textInnerHead">Calls Respond analysis</h3>
              <div className='graphContainer'>
                {/* <Chart
                  chartType="AreaChart"
                  data={tokenData}
                  options={tokenOptions}
                  legendToggle
                /> */}
                <img src='https://www.pngall.com/wp-content/uploads/12/Graph-PNG-Images-HD.png' className='graphImg' alt='Graph' />
              </div>
              <div className='respondInformation mt-3'>
                <div className='respondInfoinner_'>
                  <h3 className='heading_ '>2.1k</h3>
                  <p className='textInner'>New Tickets</p>
                </div>
                <div className='respondInfoinner_'>
                  <h3 className='heading_ '>2.1k</h3>
                  <p className='textInner'>Assigned</p>
                </div>
                <div className='respondInfoinner_'>
                  <h3 className='heading_ '>2.1k</h3>
                  <p className='textInner'>Unassigned</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='recentTicketsolve'>
        <h3 className='textInnerHead mb-4'>Recent Tickets solved</h3>
        <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
      </div>
    </div>
  )
}

export default SupportOverview