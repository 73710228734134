import React from 'react'

const SuportticketModal = () => {
  return (
    <div className='addEmployeeModal '>
        <form className='commonbody_ horizontalForms'>
           <div className='row'>
           
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Select Department</label>
                    <select className='w-100 select selectbox_'>
                        <option selected>Call Center</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Select Agent</label>
                    <select className='w-100 select selectbox_'>
                        <option selected>Select Agent</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
               
                <div className='footerSec mt-4'>
                    <button to='' className='secoundaryBtn me-4'>Cancel</button>
                    <button to='' className='assignBtn_'>Assign Now</button>
                </div>
           </div>
        </form>
    </div>
  )
}

export default SuportticketModal