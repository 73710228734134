import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { verifidecart, sila, shield, userImgg, Verfidsky, licence, docupload, Viewimg, downloadimg } from '../../../../utilities/images'
const VerifiedDetails = () => {
  const history = useHistory();

  document.title = "VerifiedDetails";
  return (
    <>
      <div className='topHeader_'>
        <div className='topStickyHeader_ mb-3'>
          <div className='row  align-items-center'>
            <div className='col-md-6'>
              <h2 className='anotherHead' onClick={() => { history.push("/verification/verified") }}><i class="fal fa-angle-left"></i> Applicant details </h2>
            </div>
          </div>
        </div>
      </div>
      <div className='applicantDetails'>
        <div className='row'>
          <div className='col-sm-7 col-md-7'>
            <div className='chartsOuter mb-3'>
                <div className='aproveInfo'>
                <div className='aproveInfoinner'>
                    <p className='smallHeading'>Request date:   <span className='approveStatus'>Nov 29 2022</span></p>
                    <p className='smallHeading'>Request Time:   <span className='approveStatus'>10:36:59 am</span></p>
                </div>
                <div className='aproveInfoinner'>
                    <p className='smallHeading'>Request date:   <span className='approveStatus'>Nov 29 2022</span></p>
                    <p className='smallHeading'>Request Time:   <span className='approveStatus'>10:36:59 am</span></p>
                </div>  
                <div className='verifiedImg'>
                    <img src={verifidecart} />
                </div>
             </div>
            </div>
            <div className='chartsOuter'>
                <div className='verifydetailHead mb-3'>
                    <h5 className='deatilsHead'>Applicant Personal Details</h5>
                    <div className='applicationNum'>
                        <img src={shield} />
                        <h5 className='innerSubtext'>260 101 480 0083</h5>
                    </div>
              </div>
              <img src={userImgg} alt="userImg" />
              <div className='personalDetails'>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Phone number</span>
                  <strong className='innerContent'>(319) 424-4476</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>SSN</span>
                  <strong className='innerContent'>***-**-1234</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Username</span>
                  <strong className='innerContent'>@little.vallie</strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>First Name</span>
                  <strong className='innerContent'>Talon </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Last Name</span>
                  <strong className='innerContent'>Smith </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Gender</span>
                  <strong className='innerContent'>Male </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Age</span>
                  <strong className='innerContent'>25 </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Date of Birth</span>
                  <strong className='innerContent'>January 30, 1991 </strong>
                  <img src={Verfidsky} />
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Address</span>
                  <strong className='innerContent'>880 Lizeth Shores Pompano Beach, FL 33072</strong>
                  <img src={Verfidsky} />
                </h5>
              </div>
            </div>
            <div className='chartsOuter mt-3'>
              <h5 className='deatilsHead'>Payment Details</h5>
              <div className='paymentDetails'>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Bank Account</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Credit/Debit</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Apple pay</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
                <h5 className='detailsHeadinner'>
                  <span className='contentHeading'>Paypal</span>
                  <small className='itallicHeading'>not update yet</small>
                </h5>
              </div>
            </div>
          </div>
          <div className='col-sm-5 col-md-5'>
            <div className='chartsOuter approvedBy mb-3'>
                <h5>Approved by:</h5>
                <img src={sila} />
            </div>
            <div className='chartsOuter'>
              <div className='driverLicence'>
              <div className='verifydetailHead mb-3'>
                    <h5 className='deatilsHead'>Driver's License</h5>
                     <img src={Verfidsky} />
              </div>
                <div className='licenceIfomation'>
                  <img src={licence} />
                  <div className='drivepersonal'>
                    <h5>Talon Smith</h5>
                    <p><strong>DLN:</strong> 71FHB40203</p>
                    <h6>880 Lizeth Shores Pompano Beach, Florida - 33072</h6>
                  </div>
                </div>
                <div className='drivepersonalInfo mt-3'>
                  <div className='row'>
                    <div className='personalinfoHead col-md-6'><strong>DOB:</strong> <span>07/15/1952</span></div>
                    <div className='personalinfoHead col-md-6'><strong>CLASS:</strong> <span>A</span></div>
                    <div className='personalinfoHead col-md-6'><strong>SEX:</strong> <span>M</span></div>
                    <div className='personalinfoHead col-md-6'><strong>ISS:</strong> <span>03/02/2022</span></div>
                    <div className='personalinfoHead col-md-6'><strong>HGT:</strong> <span>6'0"</span></div>
                    <div className='personalinfoHead col-md-6'><strong>EXP:</strong> <span>03/02/2022</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='chartsOuter mt-3'>
                <div className='verifydetailHead mb-3'>
                    <h5 className='deatilsHead'>Uploaded Documents</h5>
                     <img src={Verfidsky} />
              </div>
              <div className='uploadDocument mb-3'>
                <div className='uploadContent'>
                  <h6>Identity type: Driving License-Front</h6>
                  <div className='docFileupload'>
                    <img src={docupload} />
                    <span>talon_driving_licence </span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
              <div className='uploadDocument mb-2'>
                <div className='uploadContent'>
                  <h6>Identity type: Driving License-Back</h6>
                  <div className='docFileupload'>
                    <img src={docupload} />
                    <span>talon_driving_licence </span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
              <div className='uploadDocument mb-2'>
                <div className='uploadContent'>
                  <h6>Confirm your identify</h6>
                  <div className='docFileupload'>
                    <img src={docupload} />
                    <span>talon_driving_licence </span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimg} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimg} /></Link>
                </div>
              </div>
            </div>
            <div className='chartsOuter mt-3'>
              <div className='drivepersonalInfo'>
                <h5 className='deatilsHead'>Device Info</h5>
                <div className='row'>
                  <div className="col-md-6"><p className='deviceHead'>Device</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>iPhone</p></div>
                  <div className="col-md-6"><p className='deviceHead'>IP Address (IPv4):</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>111.174.210.87</p></div>
                  <div className="col-md-6"><p className='deviceHead'>MAC Address:</p></div>
                  <div className="col-md-6"><p className='deviceHead fw-500'>35:06:2A:4F:12:0C</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifiedDetails;