import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { user, userImgg } from '../../../../utilities/images'
import DataTable from 'react-data-table-component';
import products from "../../../db/category.json";

const UserviewList = () => {
    const [key, setKey] = useState(Math.random());
    const history = useHistory()
    const [activeTab, setActiveTab] = useState('logHistory');
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
      });
      const handleOnCloseModal = () => {
        setModalDetail({
          show: false,
          title: "",
          flag: "",
        });
        setKey(Math.random());
      };
      const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        {
            name: "Name",
            selector: row => {
            return (
                  <>
                    <div className='d-flex align-items-center'>
                      <img className='userInnerImg' src={user} />
                      <div className='ms-2'>
                        <span className='textParagh '>Curtis M. Wheeler</span>
                      </div>
                    </div>
                  </>
                );
            },
            width: "200px", 
          },
        
        { name: 'Join date',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>05 May, 2022</span>
                </>
              );
          },
          width: "180px",
        },
        { name: 'Age',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>23</span>
                </>
              );
          },
          width: "100px",
        },
        { name: 'Gender',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Male</span>
                </>
              );
          },
          width: "220px",
        },
        {
            name: 'Status',
            selector: row => {
              return (
                    <>
                      <div className='ms-2 tableBtn__'>
                          <Link to='' className='greenBtn_'>Active</Link>
                          {/* <i class="fal fa-chevron-right ms-3" ></i>  */}
                      </div>
                    </>
                  );
              },
              width: "160px"
          },
          {
            name: '',
            selector: row => {
                return (
                    <>
                        <div className='dropdown tableDropdown'>
                            <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                <i class="fal fa-ellipsis-v icon_"></i>
                            </span>
                            <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                <li className='dropdown-item' onClick={() => history.push ('/systemConfig/viewlogHistory')}>View log history</li>
                                <li className='dropdown-item' onClick={() => history.push ('/systemConfig/viewrolePermission')}>View role permission</li>
                                <li className='dropdown-item'>Edit role</li>
                                <li className='dropdown-item'>Disable role</li>
                                <li className='dropdown-item'>Profile</li>
                            </div>
                        </div>
                    </>
                );
            },
            width: "50px",
        },
        // {
        //   selector: row => row.types,
        //   name: 'Type'
        // },
        // {
        //   selector: row => row.fees,
        //   name: 'Fees'
        // },
    ];
    
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
    return (
        <div className='systemEmplyInvoice'>
            <div className='topHeader_'>
                <div class="topStickyHeader_ mb-3">
                    <div class="row  align-items-center">
                        <div class="col-md-6">
                            <div className='anotherHeader'>
                            <i class="las la-angle-left" onClick={() => history.push ('/systemConfig/userRoles')}></i>
                            <h2 class="innerSubheadNormal">Role: Wallet Admin</h2>
                            {/* <span class="pendingStatus">Pending</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters row'>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Join date
                    </option>
                    <option>leave type1</option>
                    <option>leave type2</option>
                </select>
            </div>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Status
                    </option>
                    <option>2000</option>
                    <option>2001</option>
                </select>
            </div>
          </div>
        </div>
        <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
           
            
        </div>
    )
}

export default UserviewList