import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetrightimg } from "../../../utilities/images";
import { resetPasswordAsync, verifyPasswordAsync } from "./authSlice";

const ResetPassword = () => {
  const history = useHistory();
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [verifyLoader, setVerifyLoader] = useState(false)
  const [password, setPassword] = useState("")
  const toastId = React.useRef(null)
  const [confirmPassword, setConfirmPassword] = useState("")
  const dispatch = useDispatch()

  const handleSubmit = async(e) => {
    e.preventDefault()
    if (!password) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your password");
      }
      return false
    }
    if (password.length < 8) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password length should be of 8 digit");
      }
      return false
    }
    if (password !== confirmPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password and confirm password should be same");
      }
      return false
    }
    let data = {
      "reset_password_uuid": id,
      "new_password": password,
      "confirm_password": confirmPassword
    }
    setLoading(true)
    await dispatch(resetPasswordAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push('/')
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    let data = {
      "uuid": id
    }
    setVerifyLoader(true)
    dispatch(verifyPasswordAsync(data))
    .then(unwrapResult)
    .then((obj) => {
      setVerifyLoader(false)
      // if (!toast.isActive(toastId.current)) {
      //   toastId.current = toast.success(obj?.msg)
      // }
    }
    )
    .catch((obj) => {
      setVerifyLoader(false)
      history.push('/')
    })
  }, [])

  return (
    <>
      <div className="commonLogin resetcommon">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Create a New Password</h4>
                  <p className="smallText">Your new password must be different from previous used passwords.</p>
                </div>
                <form className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                  <div className="mb-1 form-group">
                    <label className="form-label">New Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password here"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="textLine">Must be at least 8 characters</p>
                    <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span>
                  </div>
                  <div className="mb-1 form-group">
                    <label className="form-label">Confirm New Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password here"
                      name="password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span>
                  </div>
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn mt-4" type="submit" disabled={loading}> 
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Set New Password</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={resetrightimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="copyRight">
              <p>All Rights Reserved by JOBR LLC | 2022</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
