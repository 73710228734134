import React from 'react'
import { Link, useHistory } from 'react-router-dom'
    const CarddetailModal = () => {
    const history = useHistory()
  return (
    <>
    <div className='addEmployeeModal'>
        <form className='commonbody_ horizontalForms'>
           <div className='row'>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Credit Card Number:</label>
                    <h5 className='semiboldHeading_'>5520-8049-7291-6766</h5>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Exp Date</label>
                    <h5 className='semiboldHeading_'>5/24</h5>               
                </div>
               
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>CVV</label>
                    <h5 className='semiboldHeading_'>860</h5>           
                </div>
             
                <div className='footerSec mt-4'>
                    <button to='' className='closebtnModal me-4'>Close</button>
                   
                </div>
           </div>
        </form>
    </div>
    </>
    
  )
}

export default CarddetailModal