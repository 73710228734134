
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {editIcon, user} from '../../../../utilities/images'
const ViewrolePermission = () => {
    const history = useHistory()
    return (
        <div className='viewrolePermission'>
            <div className='topHeader_'>
                <div class="topStickyHeader_ mb-3">
                    <div class="row  align-items-center">
                        <div class="col-md-6">
                            <div className='anotherHeader'>
                            <i class="las la-angle-left" onClick={() => history.push ('/systemConfig/userviewList')}></i>
                            <h2 class="innerSubheadNormal">Role: Wallet Admin</h2>
                            {/* <span class="pendingStatus">Pending</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='viewcommonbox_'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='chartsOuter rolemaincontainer_'>
                            <div className='topheadpermit'>
                                <div className='row align-items-center'>
                                    <div className='col-md-8'>
                                        <div className='rolepermitTop'>
                                            <h5 className='subheadText'>Role Permission (Admin manager)</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-4 text-end'>
                                        <button class="valuesTimeswallet active" onClick={() => history.push ('/systemConfig/rolepermissionEdit')}>
                                            <img className='editIconn' src={editIcon} />
                                            <span className='ms-2'>Edit</span>
                                        </button>
                                    </div>
                                </div>
                             </div>
                             <div className='middlebodycontain_'>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Orders Managment</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5> 
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Invetory Managment</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ mb-3'>Add Inventory</h5> 
                                    <h5 className='contentheadtext_ mb-3'>Adjust Inventory</h5>
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5>
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Sales Management</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5>
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Wallet Management</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ mb-3'>Payment Verifications</h5>
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5>
                                </div>
                             </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='chartsOuter rolemaincontainer_'>
                                <div className='topheadpermit'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-7'>
                                                <div className='rolepermitTop'>
                                                    <h5 className='subheadText'>Assigned Users</h5>
                                                </div>
                                            </div>
                                            <div className='col-md-5 text-end'>
                                                <button class="valuesTimeswallet active">
                                                    <span>Add user</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                     <div className='middlebodycontain_'>
                                         <div className='userInformation_'>
                                             <div className='d-flex align-items-center'>
                                              <img className='userInnerImg' src={user} />
                                                <div className='ms-2'>
                                                  <span className='textParagh '>Teri J Henderson</span>
                                                 </div>
                                             </div>
                                             <div className='d-flex align-items-center'>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked/>
                                                    <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='userInformation_ deletefade_'>
                                             <div className='d-flex align-items-center'>
                                              <img className='userInnerImg' src={user} />
                                                <div className='ms-2'>
                                                  <span className='textParagh '>Teri J Henderson</span>
                                                 </div>
                                             </div>
                                             <div className='d-flex align-items-center'>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                                    <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default ViewrolePermission