import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { loginrightimg } from "../../../utilities/images";
import { useDispatch } from "react-redux";
import { loginAsync } from "./authSlice";

const Login = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [userName, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const toastId = React.useRef(null)
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!userName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your username");
      }
      return false
    }
    if (!password) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your password");
      }
      return false
    }
    let data = {
      "email": userName,
      "password": password
    }
    setLoading(true)
    dispatch(loginAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        console.log(obj, "object")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        if (obj?.payload?.is_google_auth_enable) {
          history.push('/authenticate')
        } else {
          history.push('/wallet')
        }

      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }


  return (
    <>
      <div className="commonLogin">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Login</h4>
                  <p className="text">Hey! Enter your login details.</p>
                </div>
                <form className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                  <div className="mb-4 form-group">
                    <label className="form-label">User Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Email Address"
                      value={userName}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-1 form-group">
                    <label className="form-label">Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span>
                  </div>
                  <div className="remeberForgot form-group mb-5">
                    <p className="forgot mb-0" onClick={() => { history.push("/forgetPassword") }}>Forgot Password?</p>
                  </div>
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn" type="submit" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Login</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={loginrightimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="copyRight">
              <p>All Rights Reserved by JOBR LLC | 2022</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;