import React from 'react'
import {
    licencsLarge
  } from "../../../../utilities/images";

const DetailkycModal = (props) => {
 
  return (
    <>
   <div className="commonModal">
      <div className="modalContent">
        <img className='licenceImgae' src={licencsLarge} />
      </div>
    </div>
    </>
  )
}

export default DetailkycModal