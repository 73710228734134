import React from 'react';
import DashboardNavbar from '../../containers/dashboard/DashboardNavbar';
import Sidebar from './Sidebar';

const DashboardLayout = (props) => {
    return (
        <main>
            <div className="wrapper">
                <Sidebar />
                <div className="rightWrapper">
                    <DashboardNavbar />
                    <div className='container-fluid pt-3 pb-3'>
                        {props.children}
                    </div>
                </div>
                {/* <Footer {...props}/> */}
            </div>
        </main>
    )
}

export default DashboardLayout;