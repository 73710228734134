import React from 'react'
import { Link, useHistory } from 'react-router-dom'
    const AddbankaccountModal = () => {
    const history = useHistory()
  return (
    <div className='addEmployeeModal commonBody'>
        <form className='commonbody_ horizontalForms'>
           <div className='row'>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Routing Number</label>
                    <input type='text' className='customform-control' placeholder='Rounting Number' value='' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Account Number</label>
                    <input type='text' className='customform-control' placeholder='Account Number' value='' />
                </div>
               
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Confirm Account Number</label>
                    <input type='text' className='customform-control' placeholder='Account Number' value='' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Bank Name</label>
                    <input type='email' className='customform-control' placeholder='Bank Name' value='' />
                </div>

                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Account type</label>
                    <select className='w-100 selectbox_'>
                        <option selected>Seclect acount type</option>
                        <option>Wallet Admin</option>
                        <option>Wallet Admin</option>
                    </select>
                </div>
                <div className='footerSec mt-4'>
                    <button to='' className='secoundaryBtn me-4'>Cancel</button>
                    <button to='' className='submitBtn'>Add Bank account</button>
                </div>
           </div>
        </form>
    </div>
    
  )
}

export default AddbankaccountModal