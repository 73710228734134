import React from 'react'
import { Link, useHistory } from 'react-router-dom'
    const DebitcreditdetailModal = () => {
    const history = useHistory()
  return (
    <div className='addEmployeeModal '>
        <form className='commonbody_ horizontalForms'>
           <div className='row'>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Card Number</label>
                    <input type='text' className='customform-control' placeholder='Card Number' value='' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Card Holder Name</label>
                    <input type='text' className='customform-control' placeholder='Account Number' value='' />
                </div>
               
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Card Expire Date</label>
                    <input type='text' className='customform-control' placeholder='mm / yy' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>CVV</label>
                    <input type='email' className='customform-control' placeholder='Bank Name' value='' />
                </div>

                
                <div className='footerSec mt-4'>
                    <button to='' className='secoundaryBtn me-4'>Cancel</button>
                    <button to='' className='submitBtn'>Add Card</button>
                </div>
           </div>
        </form>
    </div>
    
  )
}

export default DebitcreditdetailModal