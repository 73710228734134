import React from 'react'
import { Link } from 'react-router-dom';
import { downlight, shareMap } from '../../../utilities/images'
// import { Chart } from "react-google-charts";

const Overview = () => {
    document.title = "Overview";

    const salesOptions = {
        pieHole: 0.4,
        is3D: false,
        slices: { 0: { color: '#275AFF' }, 1: { color: '#3C444D' }, 2: { color: 'FB466C' } },
        height: 300,
        width: 300,
    };

    const salesData = [
        ["", ""],
        ['Verified', 90.33],
        ['Pending', 7],
        ['Denied', 2.69]
    ];
    const tokenOptions = {
        title: "Fees earning analysis",
        // isStacked: true,
        height: 300,
        width: 250,
        series: [{ "color": "#275AFF" }],
        intervals: { "style": "area" },
        curveType: "function",
        legend: { position: "top", maxLines: 6 },
        vAxis: { minValue: 0 },
    };

    const tokenData = [
        ["", ""],
        ['S', 42],
        ['M', 20],
        ['T', 54],
        ['W', 35],
        ['T', 44],
        ['F', 77],
        ['S', 66]
    ];
    const data = [
        ["Country", "Popularity"],
        ["Germany", 200],
        ["United States", 300],
        ["Brazil", 400],
        ["Canada", 500],
        ["France", 600],
        ["RU", 700],
      ];
      const options = {
        height: 400,
        width: 700,
        intervals: { "style": "area" },
        colors: ['#275AFF'],
        // displayMode: 'text'
        // datalessRegionColor:"#275AFF"
        // displayMode: 'text'
        // curveType: "function",
        // legend: { position: "top", maxLines: 6 },
        // vAxis: { minValue: 0 },
    };
    return (
        <>
            <div className='verification'>
                <div className='row mb-3'>
                    <div className='col-md-6'>
                        <Link to="#" className='valuesTimeswallet active'>KYC & SSN</Link>
                        <Link to="#" className='valuesTimeswallet'>KYB & EIN</Link>
                    </div>
                    <div className='col-md-6  text-end'>
                        <div class="dropdown weekDropdown">
                            <button class="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                This week <img src={downlight} alt="img" />
                            </button>
                            <ul class="dropdown-menu weekDropdownMenus">
                                <li><a class="dropdown-item">This week</a></li>
                                <li><a class="dropdown-item">Last Week</a></li>
                                <li><a class="dropdown-item">This Month</a></li>
                                <li><a class="dropdown-item">This Quarter </a></li>
                                <li><a class="dropdown-item">This Year</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='verificationTotal'>
                    <div className='row'>
                        <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                            <div className='percentageContainer'>
                                <div className='percentageValues'>
                                    <h3 className='heading_ '>10,029</h3>
                                    <p className='textInner'>Total Applied</p>
                                </div>
                                <div className='percentageRating'>
                                    <img src={shareMap} className="" alt='ShareMap' />
                                    <p className='percentageRate'>1.2%</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                            <div className='percentageContainer'>
                                <div className='percentageValues'>
                                    <h3 className='heading_ '>9,960</h3>
                                    <p className='textInner'>Total Verified</p>
                                </div>
                                <div className='percentageRating'>
                                    <img src={shareMap} className="" alt='ShareMap' />
                                    <p className='percentageRate'>1.2%</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                            <div className='percentageContainer'>
                                <div className='percentageValues'>
                                    <h3 className='heading_ '>163</h3>
                                    <p className='textInner'>New Applications</p>
                                </div>
                                <div className='percentageRating'>
                                    <img src={shareMap} className="" alt='ShareMap' />
                                    <p className='percentageRate'>1.2%</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                            <div className='percentageContainer'>
                                <div className='percentageValues'>
                                    <h3 className='heading_ '>3</h3>
                                    <p className='textInner'>Application denied</p>
                                </div>
                                <div className='percentageRating'>
                                    <img src={shareMap} className="" alt='ShareMap' />
                                    <p className='percentageRate'>1.2%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='productDatatwallet mt-2 mb-2'>
                    <div className='row'>
                        <div className='col-sm-6 col-md-6 col-lg-6 mt-3 mb-3'>
                            <div className='chartsOuter'>
                                <div className='row'>
                                    <div className='col-md-4 col-xl-5 col-xxl-4 outerBorder'>
                                        <ul className='productDetailsNumber'>
                                            <li className='productDetailsItems'>
                                                <h5 className='productDetails'>Verified</h5>
                                                <h3 className='productDetailsValues headBlue'>90.33%</h3>
                                            </li>
                                            <li className='productDetailsItems'>
                                                <h5 className='productDetails'>Pending</h5>
                                                <h3 className='productDetailsValues headGrey'>7%</h3>
                                            </li>
                                            <li className='productDetailsItems'>
                                                <h5 className='productDetails'>Denied</h5>
                                                <h3 className='productDetailsValues headPink'>2.69%</h3>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-md-8 col-xl-7 col-xxl-8 chartProduct'>
                                        {/* <Chart
                                            chartType="PieChart"
                                            data={salesData}
                                            options={salesOptions}
                                            legendToggle
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-6 mt-3 mb-3'>
                            <div className='chartsOuter'>
                                <h3 className='textInnerHead'>New Applications</h3>
                                <div className='row'>
                                    <div className='col-md-8 col-xl-7 col-xxl-8 chartProduct'>
                                        {/* <Chart
                                            chartType="Bar"
                                            data={tokenData}
                                            options={tokenOptions}
                                            legendToggle
                                        /> */}
                                    </div>
                                    <div className='col-md-4 col-xl-5 col-xxl-4 outerBorder'>

                                        <ul className='productDetailsNumber'>
                                            <li className='productDetailsItems'>
                                                <h5 className='gerytextHead'>Today</h5>
                                                <h3 className='blacktextHead'>156</h3>
                                            </li>
                                            <li className='productDetailsItems'>
                                                <h5 className='gerytextHead'>This Week</h5>
                                                <h3 className='blacktextHead'>2,560</h3>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='reasonalApplication mt-2 mb-2'>
                    <div className='row'>
                        <div className='col-md-9 col-lg-9 col-xl-8 col-xxl-9'>
                            <div className='chartsOuter'>
                                <h3 className='textInnerHead'>Regional applications </h3>
                                {/* <Chart
                                    chartEvents={[
                                        {
                                            eventName: "select",
                                            callback: ({ chartWrapper }) => {
                                                const chart = chartWrapper.getChart();
                                                const selection = chart.getSelection();
                                                if (selection.length === 0) return;
                                                const region = data[selection[0].row + 1];
                                                console.log("Selected : " + region);
                                            },
                                            series: [{ "color": "#275AFF" }],
                                        },
                                    ]}
                                    chartType="GeoChart"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}
                                /> */}
                            </div>
                        </div>
                        <div className='col-md-3 col-lg-3 col-xl-4 col-xxl-3'>
                            <div className='chartsOuter'>
                                <h3 className='headingBlue'>2 Countries</h3>
                                <h4 className='subheadBlue'>33 Sates</h4>
                                <h5 className='subheadText'>230 Cities</h5>
                                <div className='countriesDetails mt-3'>
                                    <div className='countryInnerderail'>
                                        <div class="countryCount">
                                            <span>1</span>
                                        </div>
                                        <div className='countryContent'>
                                            <h4 className='innerSubtext'>Florida</h4>
                                            <h5 className='textInner'>2,635 Active users</h5>
                                        </div>
                                    </div>
                                    <div className='countryInnerderail'>
                                        <div class="countryCount">
                                            <span>2</span>
                                        </div>
                                        <div className='countryContent'>
                                            <h4 className='innerSubtext'>California</h4>
                                            <h5 className='textInner'>1,635 Active users</h5>
                                        </div>
                                    </div>
                                    <div className='countryInnerderail'>
                                        <div class="countryCount">
                                            <span>3</span>
                                        </div>
                                        <div className='countryContent'>
                                            <h4 className='innerSubtext'>New York</h4>
                                            <h5 className='textInner'>1,135 Active users</h5>
                                        </div>
                                    </div>
                                    <div className='countryInnerderail'>
                                        <div class="countryCount">
                                            <span>4</span>
                                        </div>
                                        <div className='countryContent'>
                                            <h4 className='innerSubtext'>Michigan</h4>
                                            <h5 className='textInner'>955 Active users</h5>
                                        </div>
                                    </div>
                                    <div className='countryInnerderail'>
                                        <div class="countryCount">
                                            <span>5</span>
                                        </div>
                                        <div className='countryContent'>
                                            <h4 className='innerSubtext'>Alabama</h4>
                                            <h5 className='textInner'>610 Active users</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview