import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { forgetpassimg } from "../../../utilities/images";
import CustomModal from '../../components/shared/CustomModal';
import ScheduleModal from './ScheduleModal';
import { forgetPasswordAsync } from "./authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

const ForgetPassword = () => {
  const [key, setKey] = useState(Math.random());
  const [userName, setUsername] = useState("")
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    if (!userName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your email");
      }
      return false
    }
    setLoading(true)
    let data = {
      "type": "email",
      "email" : userName
    }
    await dispatch(forgetPasswordAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        setModalDetail({ show: true, flag: 'ScheduleModal' });
        setKey(Math.random());
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className="commonLogin forgetcommon">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Have you forgot password?</h4>
                  <p className="smallText">To reset your password, enter your email address</p>
                </div>
                <form className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                  <div className="mb-4 form-group">
                    <label className="form-label">Email address/User Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Email address/User Name"
                      value={userName}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn mt-1" type="submit"
                    disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Send Link</span>
                    </button>
                    <p className="mt-4 gobacklink justify-content-center d-flex align-items-center"><i className="fa-solid fa-arrow-left-long"></i> Go back to <Link to="#" onClick={() => { history.push("/") }}> Login </Link> </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={forgetpassimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="copyRight">
              <p>All Rights Reserved by JOBR LLC | 2022</p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "ScheduleModal" ? "scheduleModal" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={modalDetail.flag === 'ScheduleModal' ? <ScheduleModal close={() => handleOnCloseModal()} />
          : ""
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ForgetPassword;