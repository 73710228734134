import moment from 'moment-timezone';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { calendar } from '../../../../utilities/images';
import products from "../../../db/category.json";
const LeaveHistory = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        {
          name: "Leave Starts",
          selector: row => {
          return (
                <>
                  <span className='textParagh '>Nov 5, 2022</span>
                </>
              );
          },
          width: "130px", 
        },
        { name: 'Join Back',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Sep 17, 2022</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'Leave Type',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Casual</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'Reason',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Going to Family Function</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'Duration',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>1 Days</span>
                </>
              );
          },
          width: "220px",
        },
    ];
    
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='logHistory'>
         <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters row'>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    leave type
                    </option>
                    <option>leave type1</option>
                    <option>leave type2</option>
                </select>
            </div>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Year
                    </option>
                    <option>2000</option>
                    <option>2001</option>
                </select>
            </div>
          </div>
        </div>
         <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
    </div>
  )
}

export default LeaveHistory