import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import AssignUser from './assignUser';
import Roles from './roles';
const UserRoles = () => {
  const [key, setKey] = useState(Math.random());
  const [activeTab, setActiveTab] = useState('roles');
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  return (
    <>
    <div className='instantWithdrawal'>
        <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-12">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>User Role</span> </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <Link to="#" className={`${activeTab=='roles'? 'valuesTimeswallet active': ''} valuesTimeswallet`} onClick={()=>{setActiveTab('roles')}}>Roles</Link>
            <Link to="#" className={`${activeTab=='assignUser'? 'valuesTimeswallet active': ''} valuesTimeswallet`}
                    onClick={()=>{setActiveTab('assignUser')}}>Assigned Users</Link>
          </div>
        </div>
        <div className='logTabsDetails'>
                {activeTab == 'roles' 
                ?
                    <Roles/>
                :
                    <AssignUser />
                }
            </div>
       
    </div>
    
  </>
  )
}

export default UserRoles