import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { exportImg, imgGallery } from '../../../../utilities/images'

const AddEmployeeModal = () => {
    const history = useHistory()
  return (
    <div className='addEmployeeModal commonBody'>
        <form className='emplyoeeDetails horizontalForms'>
           <div className='row'>
            <div className='form-group col-md-6  mb-4'>
                    <label className='labelTxt mb-3' for='amount'>First name</label>
                    <input type='text' className='customform-control' placeholder='First Name' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Last name</label>
                    <input type='text' className='customform-control' placeholder='Last Name' value='' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Email address</label>
                    <input type='email' className='customform-control' placeholder='Email address' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Employee ID</label>
                    <input type='text' className='customform-control' placeholder='Employee ID' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Join date</label>
                    <input type='text' className='customform-control' placeholder='dd / mm/ yyyy' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Phone number</label>
                    <input type='number' className='customform-control' placeholder='+1' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Password</label>
                    <input type='password' className='customform-control' placeholder='Password' value='dd / mm/ yyyy' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Department</label>
                    <select className='w-100 select'>
                        <option selected>Department</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Dedignation</label>
                    <select className='w-100 select'>
                        <option selected>Dedignation</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Description</label>
                    <textarea className='customform-control textAreaSize' placeholder='Description'></textarea>
                </div>
                <div className='form-group col-md-12 mb-4 text-start'>
                    <div>
                        <label className='deatilsHead fw-bold mb-3' for='amount'>
                            Add Photo <span className='subtextSmall_'><i>(2 images not more than 1 mb)</i></span> 
                        </label>
                        <div id='dropzone'>
                            <input type="file" id="file" accept="image/*" class="form-control d-none" />
                            <label for="file">
                                <span class="file-button">
                                    <img src={imgGallery} class="img-fluid editProfileImg" alt="user img" />
                                    <div className='dropText ms-4'>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.49996 3.95703L8.14641 3.60348L8.49996 3.24992L8.85351 3.60348L8.49996 3.95703ZM8.99996 9.95703C8.99996 10.2332 8.7761 10.457 8.49996 10.457C8.22382 10.457 7.99996 10.2332 7.99996 9.95703L8.99996 9.95703ZM4.81307 6.93681L8.14641 3.60348L8.85351 4.31058L5.52018 7.64392L4.81307 6.93681ZM8.85351 3.60348L12.1868 6.93681L11.4797 7.64392L8.14641 4.31058L8.85351 3.60348ZM8.99996 3.95703L8.99996 9.95703L7.99996 9.95703L7.99996 3.95703L8.99996 3.95703Z" fill="#275AFF"/>
                                            <path d="M3.83337 11.293L3.83337 11.9596C3.83337 12.696 4.43033 13.293 5.16671 13.293L11.8334 13.293C12.5698 13.293 13.1667 12.696 13.1667 11.9596V11.293" stroke="#275AFF"/>
                                        </svg>
                                        <span className='textinner_'>Drop your files here or</span>
                                        <Link to='' className='linkTxt ms-1'>Browse</Link>
                                    </div>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='footerSec mt-4'>
                    <button to='' className='secoundaryBtn me-4'>Cancel</button>
                    <button to='' className='submitBtn'  onClick={() => history.push('/systemConfig/systemEmployeeInvoice')}>Add Employee</button>
                </div>
           </div>
        </form>
    </div>
  )
}

export default AddEmployeeModal