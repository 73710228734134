import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bell, chat, search_light, Search_light, userImg } from '../../../utilities/images'
import { openNav } from '../../redux/actions/nav';
import { logout } from '../auth/authSlice';

function DashboardNavbar(props) {
  const history = useHistory()
  const dispatch = useDispatch()

  const openActions = () => {
    document.body.setAttribute("style", "overflow:hidden");
  }
  const handleLogout = async () => {
    await dispatch(logout())
    window.localStorage.clear()
    await history.push('/')
  }

  return (
    <nav className='mainHeader'>
      <div className='row align-items-center'>
        <span
          onClick={() => {
            props.openNav();
            openActions()
          }}
          className="col-1 col-sm-1 col-md-1 openbtn2"><i className="fa fa-bars" aria-hidden="true"></i></span>

        {/* <span className="col-md-1 openbtn" onClick={window['openNav']}><i className="fas fa-bars"></i></span> */}
        <div className='col-6 col-sm-4 col-md-4 col-lg-4 '>
          <div className="input-group search_group">
            <img src={search_light} alt="" className="img-fluid searchIcon" />
            <input type="text" className="form-control" placeholder="Search here" aria-label="Recipient's username" aria-describedby="basic-addon2" />
          </div>
        </div>
        <div className='col-5 col-sm-7 col-md-7 col-lg-8 text-end'>
          <ul className='rightNav'>
            <li className='rightNavItems'>
              <Link to='#'>
                <img src={chat} className='icons_' alt='ChatImg' />
              </Link>
            </li>
            <li className='rightNavItems'>
              <Link to='#'>
                <img src={bell} className='icons_' alt='BellImg' />
              </Link>
            </li>
            <li className='rightNavItems'>
              <div className='dropdown adminDropdown' >
                <Link to="#" id='dropdownAdminButton' data-bs-toggle='dropdown' aria-expanded='false'>
                  <span className='nameUser'>System Admin</span>
                  <span className='userProfile'>
                    <img src={userImg} className='userImg me-2' alt='UserImage' />
                    <i className="fal fa-chevron-down"></i>
                  </span>
                </Link>
                <ul className='dropdown-menu dropdownAdminMenus' aria-labelledby="dropdownAdminButton">
                  <li className='dropdown-item'>
                    <Link to='#' className='dropdown-link'>Profile</Link>
                  </li>
                  <li className='dropdown-item'>
                    <Link to='#' className='dropdown-link'>Edit</Link>
                  </li>
                  <li className='dropdown-item'>
                    <Link to='#' className='dropdown-link'>Setting</Link>
                  </li>
                  <li className='dropdown-item'>
                    <Link to='#' className='dropdown-link' onClick={handleLogout}>Logout</Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  openNav: () => dispatch(openNav()),

});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);