import React from 'react'
import { Link, useHistory } from 'react-router-dom'
    const AccountdetailsModal = () => {
    const history = useHistory()
  return (
    <>
    <div className='addEmployeeModal'>
        <form className='commonbody_ horizontalForms'>
           <div className='row'>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Routing Numberr</label>
                    <h5 className='semiboldHeading_'>75902227</h5>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Account Number</label>
                    <h5 className='semiboldHeading_'>6817644562</h5>               
                </div>
               
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Bank Name</label>
                    <h5 className='semiboldHeading_'>PORT WASHINGTON STATE BANK</h5>           
                </div>
             
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Account type</label>
                    <h5 className='semiboldHeading_'>PORT WASHINGTON STATE BANK</h5> 
                </div>
                <div className='footerSec mt-4'>
                    <button to='' className='closebtnModal me-4'>Close</button>
                   
                </div>
           </div>
        </form>
    </div>
    </>
    
  )
}

export default AccountdetailsModal