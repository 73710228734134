import React from 'react'
import { Link } from 'react-router-dom'
import { editIcon} from '../../../utilities/images';


const TermsandCondition = () => {
  return (
    <div className='termsConditions_'>
      <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-6">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>Terms and Conditions</span> </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='termsConditioncontent'>
            <div className='row'>
            <div className='col-lg-3'>
                <div className='innerContentterms_'>
                  <div className='termstopHead_ '>
                    <h6 className='commonsmallHead__'>Publish date:</h6>  
                    <h6 className='commonsmallgeryHead_'>Sep 27, 2023 10: 00 AM</h6>
                      <Link to="#" className="activeBtn__">Active</Link>
                  </div>
                  <div className='termsmiddleContent_'>
                    <h5 className='commonsmallBold__'>
                    Terms and Conditions for Company Name
                    </h5>
                    <h5 className='commonsmallBold__'>
                    Introduction 
                    </h5>
                    <h5 className='tablesubHead '>
                    These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com. These Terms will be applied fully and affect to your use of
                    </h5>
                  </div>
                  <div className='termsfooterContent_'>
                    <div className='footerLeft'>
                    <h6 className='commonsmallHead__'>Last update date:</h6>  
                    <h6 className='commonsmallgeryHead_'>Sep 27, 2023 10: 00 AM</h6>
                    </div>
                    <div className='termsbtn'>
                      <Link to="#" className="termseditBtn"> <img className="editterms" src={editIcon} /> Edit </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='innerContentterms_'>
                  <div className='termstopHead_ '>
                    <h6 className='commonsmallHead__'>Publish date:</h6>  
                    <h6 className='commonsmallgeryHead_'>Sep 27, 2023 10: 00 AM</h6>
                      <Link to="#" className="activeBtn__">Active</Link>
                  </div>
                  <div className='termsmiddleContent_'>
                    <h5 className='commonsmallBold__'>
                    Terms and Conditions for Company Name
                    </h5>
                    <h5 className='commonsmallBold__'>
                    Introduction 
                    </h5>
                    <h5 className='tablesubHead '>
                    These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com. These Terms will be applied fully and affect to your use of
                    </h5>
                  </div>
                  <div className='termsfooterContent_'>
                    <div className='footerLeft'>
                    <h6 className='commonsmallHead__'>Last update date:</h6>  
                    <h6 className='commonsmallgeryHead_'>Sep 27, 2023 10: 00 AM</h6>
                    </div>
                    <div className='termsbtn'>
                      <Link to="#" className="termseditBtn"> <img className="editterms" src={editIcon} /> Edit </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default TermsandCondition