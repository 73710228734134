import moment from 'moment-timezone'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { calendar, chatIcon, flagstate, marchantpro, merchantActive, shield, user, userImg, userImgg } from '../../../../utilities/images'
import LogHistory from './logHistory';
import LeaveHistory from './leaveHistory';
import CustomModal from '../../../components/shared/CustomModal';
import EmployeeDetails from './employeeDetailsModal';
const SystemEmployeeInvoice = () => {
    const [key, setKey] = useState(Math.random());
    const history = useHistory()
    const [activeTab, setActiveTab] = useState('logHistory');
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
      });
      const handleOnCloseModal = () => {
        setModalDetail({
          show: false,
          title: "",
          flag: "",
        });
        setKey(Math.random());
      };
    return (
        <div className='systemEmplyInvoice'>
            <div className='topHeader_'>
                <div class="topStickyHeader_ mb-3">
                    <div class="row  align-items-center">
                        <div class="col-md-6">
                            <div className='anotherHeader'>
                            <i class="las la-angle-left"></i>
                            <h2 class="innerSubheadNormal">Employee ID: 97976397</h2>
                            {/* <span class="pendingStatus">Pending</span> */}
                            </div>
                        </div>
                        <div className='col-md-6 text-end'>
                            <button class="valuesTimeswallet active"
                                onClick={() => {
                                    setModalDetail({ show: true, flag: "EmployeeDetails" });
                                    setKey(Math.random());
                                  }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 7.5L5.92819 14.0718C5.71566 14.2843 5.60939 14.3906 5.53953 14.5212C5.46966 14.6517 5.44019 14.7991 5.38124 15.0938L4.64709 18.7646C4.58057 19.0972 4.5473 19.2635 4.64191 19.3581C4.73652 19.4527 4.90283 19.4194 5.23544 19.3529L8.90621 18.6188C9.20093 18.5598 9.3483 18.5303 9.47885 18.4605C9.60939 18.3906 9.71566 18.2843 9.92819 18.0718L16.5 11.5L12.5 7.5Z" fill="#275AFF"/>
                                <path d="M5.95396 19.38L5.95397 19.38L5.9801 19.3734L5.98012 19.3734L8.60809 18.7164C8.62428 18.7124 8.64043 18.7084 8.65654 18.7044C8.87531 18.65 9.08562 18.5978 9.27707 18.4894C9.46852 18.381 9.62153 18.2275 9.7807 18.0679C9.79242 18.0561 9.80418 18.0444 9.81598 18.0325L17.0101 10.8385L17.0101 10.8385L17.0369 10.8117C17.3472 10.5014 17.6215 10.2272 17.8128 9.97638C18.0202 9.70457 18.1858 9.39104 18.1858 9C18.1858 8.60896 18.0202 8.29543 17.8128 8.02361C17.6215 7.77285 17.3472 7.49863 17.0369 7.18835L17.01 7.16152L16.8385 6.98995L16.8117 6.96314C16.5014 6.6528 16.2272 6.37853 15.9764 6.1872C15.7046 5.97981 15.391 5.81421 15 5.81421C14.609 5.81421 14.2954 5.97981 14.0236 6.1872C13.7729 6.37853 13.4986 6.65278 13.1884 6.96311L13.1615 6.98995L5.96745 14.184C5.95565 14.1958 5.94386 14.2076 5.93211 14.2193C5.77249 14.3785 5.61904 14.5315 5.51064 14.7229C5.40225 14.9144 5.34999 15.1247 5.29562 15.3435C5.29162 15.3596 5.28761 15.3757 5.28356 15.3919L4.62003 18.046C4.61762 18.0557 4.61518 18.0654 4.61272 18.0752C4.57411 18.2293 4.53044 18.4035 4.51593 18.5518C4.49978 18.7169 4.50127 19.0162 4.74255 19.2574C4.98383 19.4987 5.28307 19.5002 5.44819 19.4841C5.59646 19.4696 5.77072 19.4259 5.92479 19.3873C5.9346 19.3848 5.94433 19.3824 5.95396 19.38Z" stroke="#A6B8F1" stroke-width="1.2"/>
                                <path d="M12.5 7.5L16.5 11.5" stroke="#A6B8F1" stroke-width="1.2"/>
                                </svg>
                                <span className='ms-2'>Edit</span>
                            </button>
                            <button class="closeButton"><i class="las la-times"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='chartsOuter'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="userProfileinfo_">
                                <img className="marchantPro" src={userImgg} alt="profileimg" />
                                <div className="userProfileinforight_">
                                    <h3 className='mainHeading'>Lillie A. Moore</h3>
                                    <button className='updatePriceBtn mt-2'>Active</button>
                                    <ul className='contentextLightMenus mt-3'>
                                        <li className='contentextLightItems'>
                                            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.0404 9.37377L13.5702 10.9035C13.8076 11.1409 13.8076 11.5258 13.5702 11.7632C12.2867 13.0467 10.2548 13.1911 8.80266 12.102L8.06418 11.5481C6.69501 10.5213 5.47874 9.30499 4.45187 7.93582L3.898 7.19734C2.80894 5.74525 2.95334 3.71332 4.23682 2.42985C4.47422 2.19245 4.85912 2.19245 5.09651 2.42985L6.62623 3.95956C7.01675 4.35008 7.01675 4.98325 6.62623 5.37377L6.18116 5.81884C6.07297 5.92703 6.04615 6.09231 6.11458 6.22916C6.90568 7.81137 8.18863 9.09432 9.77084 9.88542C9.90769 9.95385 10.073 9.92703 10.1812 9.81884L10.6262 9.37377C11.0168 8.98325 11.6499 8.98325 12.0404 9.37377Z" stroke="#275AFF"/>
                                            </svg>
                                            <span className='ms-2'>4703-515-1193</span> 
                                        </li>
                                        <li className='contentextLightItems'>
                                            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="2.66675" y="4" width="10.6667" height="8" rx="2" stroke="#275AFF"/>
                                            <path d="M2.66675 6L7.10565 8.21945C7.66871 8.50098 8.33145 8.50098 8.89451 8.21945L13.3334 6" stroke="#275AFF"/>
                                            </svg>
                                            <span className='ms-2'>vidal.webe2@gmail.com</span> 
                                        </li>
                                        <li className='contentextLightItems'>
                                            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 8C13 11.2093 9.63256 13.4165 8.42475 14.105C8.15889 14.2565 7.84111 14.2565 7.57526 14.105C6.36745 13.4165 3 11.2093 3 8C3 5 5.42267 3 8 3C10.6667 3 13 5 13 8Z" stroke="#275AFF"/>
                                            <circle cx="7.99992" cy="7.9987" r="2.16667" stroke="#275AFF"/>
                                            </svg>
                                            <span className='ms-2'>4318 Daffodil Lane, Savage, Virginia(VA), 20763</span> 
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <ul className="customStatus_">
                            <li className='blueboldHead'>
                                <strong>Joined Date</strong><span className='lightsubHead'>Sep 20, 2022</span>
                            </li>
                            <li className='blueboldHead'>
                                <strong>Active Since</strong> <span className='lightsubHead'>265 days</span>
                            </li>
                            <li className='blueboldHead'>
                            <strong>Employment Type</strong><span className='lightsubHead'>Full-Time</span>
                            </li>
                            <li className='blueboldHead'>
                            <strong>Leave taken</strong><span className='lightsubHead'>3 days</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='row mb-3 mt-3'>
            <div className='col-md-12'>
                <Link to="#" 
                className={`${activeTab=='logHistory'? 'valuesTimeswallet active': ''} valuesTimeswallet`} onClick={()=>{setActiveTab('logHistory')}}>
                Log History
                </Link>
                <Link to="#" className={`${activeTab=='leaveHistory'? 'valuesTimeswallet active': ''} valuesTimeswallet`}
                    onClick={()=>{setActiveTab('leaveHistory')}}>
                    Leave History
                </Link>
            </div>
            </div>
            <div className='logTabsDetails'>
                {activeTab == 'logHistory' 
                ?
                    <LogHistory/>
                :
                    <LeaveHistory />
                }
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "EmployeeDetails" ? "commonWidth" : ""}
                ids={modalDetail.flag === "EmployeeDetails" ? "employeeDetails" : ""}
                child={
                    modalDetail.flag === "EmployeeDetails" ? (
                    <EmployeeDetails close={() => handleOnCloseModal()} />
                    ) : (
                    ""
                    )
                }
                header={
                    <div className='modalHeader_'>
                    <div className="common_">
                        <h2 className="modalHeading">
                            Employee Details
                        </h2>
                        <button class="closeButton">
                            <i class="las la-times" onClick={() => handleOnCloseModal()}></i>
                        </button>
                    </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default SystemEmployeeInvoice