import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
    Phoneimg, shield, locationimg, Cashout
} from "../../../../utilities/images";

const TokenpaymentModal = (props) => {
  const history = useHistory();
 
  return (
    <>
   <div className="commonModal">
      <div className='commonBody'>
        <div className='subHeader'>
          <div className="row">
              <div className="col-md-6">
                <div className="subheaderInner">
                  <h5 className='subhaedHeading'>Sender</h5>
                    <h6 className='subheaderHeading'>Abdullah Shafique</h6>
                    <p className='contentSubtextbold'>
                      <img src={shield} /> 
                      <span> 4703-515-1193</span> 
                    </p>
                    <p className='contentextLight'>
                      <img src={Phoneimg} />
                      <span>  vidal.webe2@gmail.com</span> 
                    </p>
                    <p className='contentextLight'>
                      <img src={locationimg}/>
                      <span>2061 Grand Avenue Orlando, FL 32801</span> 
                    </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="subheaderInner">
                  <h5 className='subhaedHeading'>Receiver</h5>
                    <h6 className='subheaderHeading'>Abdullah Shafique</h6>
                    <p className='contentSubtextbold'>
                      <img src={shield} /> <span>4703-515-1193</span>
                    </p>
                    <p className='contentextLight'>
                      <img src={Phoneimg} /> <span>vidal.webe2@gmail.com</span>
                    </p>
                    <p className='contentextLight'>
                      <img src={locationimg}/>
                      <span>2061 Grand Avenue Orlando, FL 32801</span>
                    </p>
                </div>
              </div>
          </div>
        </div>
        <div className="modalContent">
          <div className='amountDeatils_'>
              <h3 className='mainbluekHead_'>JBR $100.00</h3>
              <h5 className='mainlightHead'>Token Amount</h5>
              <img className='walletadd' src={Cashout} />

          </div>
          <div className='bankInformation'>
              <p className='infoHeading'><span className='bankheadInnr'>Transection ID</span> <span className='haedingsemiBold_'>288224966</span></p>
              <p className='infoHeading'><span className='bankheadInnr'>Date & Time</span> <span className='haedingsemiBold_'>Sep 13, 2022 7:50 PM</span></p>
          </div>
          <div className='invoiceLink'>
              <p><span className='mainlightHead'>Invoice link:</span><small>https://api.whatsapp.com/send?phone=125649&text=newlink</small></p>
          </div>
          <div className='transactionStatus'>
              <p className='mainlightHead'>Transection Status</p>
              <Link to="#" className='innerBtn_' onClick={() => { history.push("/tokenUsage/tokenpaymentInvoice") }}>Completed</Link>
          </div>
        </div>
      </div>
   </div>
    </>
  )
}

export default TokenpaymentModal