import React from 'react'
// import { Chart } from "react-google-charts";
import { downlight, graph2, graphsImg, shareMap } from '../../../utilities/images'

const FiatOverview = () => {
  document.title = 'FiatOverview'
  const feeOptions = {
    title: "Fees earning analysis",
    // isStacked: true,
    height: 350,
    width: 750,
    series: [{ "color": "#1FB3FF" }, { "color": "#6C63F0" }],
    intervals: { "style": "area" },
    curveType: "function",
    legend: { position: "top", maxLines: 7 },
    vAxis: { minValue: 0 },
  };

  const feeData = [
    ["", "Referral Trades", 'Actual Trades'],
    ['Sunday', 42, 36],
    ['Monday', 20, 34],
    ['Tuesday', 54, 67],
    ['Wednesday', 35, 45],
    ['Thursday', 44, 76],
    ['Friday', 77, 64],
    ['Saturday', 66, 87]
  ];
  const salesOptions = {
    title: "Sales Trends",
    isStacked: true,
    height: 500,
    width: 1100,
    series: [{ "color": "#275AFF" }, { "color": "#1FB3FF" }],
    curveType: "function",
    legend: { position: "top", maxLines: 7 },
    vAxis: { minValue: 0 },
  };

  const salesData = [
    ["",'Actual Trades',"Referral Trades"],
    ['Sunday', 42,36],
    ['Monday', 20,34],
    ['Tuesday', 54,67],
    ['Wednesday', 35,45],
    ['Thursday', 44,76],
    ['Friday', 77,64],
    ['Saturday', 66,87]
  ];

  return (
    <div className='fiatOverview'>
      {/* Start Week Section */}
      <div className='row mb-3'>
        <div className='col-md-12 text-end'>
          <div class="dropdown weekDropdown">
            <button class="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              This week <img src={downlight} alt="img" />
            </button>
            <ul class="dropdown-menu weekDropdownMenus">
              <li><a class="dropdown-item">This week</a></li>
              <li><a class="dropdown-item">Last Week</a></li>
              <li><a class="dropdown-item">This Month</a></li>
              <li><a class="dropdown-item">This Quarter </a></li>
              <li><a class="dropdown-item">This Year</a></li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Week Section */}
      {/* Start PercentageContainer Section */}
      <div className='verificationTotal mt-4'>
        <div className='row'>
          <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
            <div className='percentageContainer'>
              <div className='percentageValues'>
                <h3 className='heading_ '>$1.25M</h3>
                <p className='textInner'>Total Trade Value </p>
              </div>
              <div className='percentageRating'>
                <img src={shareMap} className="" alt='ShareMap' />
                <p className='percentageRate'>1.2%</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
            <div className='percentageContainer'>
              <div className='percentageValues'>
                <h3 className='heading_ '>3,000</h3>
                <p className='textInner'>Number of Trades </p>
              </div>
              <div className='percentageRating'>
                <img src={shareMap} className="" alt='ShareMap' />
                <p className='percentageRate'>1.2%</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
            <div className='percentageContainer'>
              <div className='percentageValues'>
                <h3 className='heading_ '>$300,000</h3>
                <p className='textInner'>Average value</p>
              </div>
              <div className='percentageRating'>
                <img src={shareMap} className="" alt='ShareMap' />
                <p className='percentageRate'>1.2%</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 col-lg-3 '>
            <div className='percentageContainer'>
              <div className='percentageValues'>
                <h3 className='heading_ '>25</h3>
                <p className='textInner'>Active Customers</p>
              </div>
              <div className='percentageRating'>
                <img src={shareMap} className="" alt='ShareMap' />
                <p className='percentageRate'>1.2%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End PercentageContainer Section */}
      <section className='tradesValue mt-4 mb-4'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='chartsOuter'>
              <h3 class="textInnerHead mt-3">Token Trades Value</h3>
              <div className='row mt-4 mb-4 align-items-center'>
                <div className='col-md-3'>
                  <div className='tradesContainer'>
                    <div className='tradesMain'>
                      <h4 className='innerHeadingSky'>$400,035</h4>
                      <p className='textinner_'>Referral Trades</p>
                    </div>
                    <div className='tradesMain'>
                      <h4 className='innerHeadingSky_'>$219,000</h4>
                      <p className='textinner_'>Actual Trades</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-9'>
                  {/* <Chart
                    chartType="Bar"
                    data={feeData}
                    options={feeOptions}
                    legendToggle
                  /> */}
                  <img src={graphsImg} alt="Graph Image" className='img-fluid'/>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-4'>
            <div className='chartsOuter'>
              <h3 class="textInnerHead mt-3 mb-4">Token Trades </h3>
              {/* <Chart
                chartType="LineChart"
                data={salesData}
                options={salesOptions}
                legendToggle
              /> */}
              <img src={graph2} alt='GraphImg' className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FiatOverview