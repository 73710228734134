
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {editIcon, user} from '../../../../utilities/images'
const RolepermissionEdit = () => {
    const history = useHistory()
    
    return (
        <div className='viewrolePermission'>
            <div className='topHeader_'>
                <div class="topStickyHeader_ mb-3">
                    <div class="row  align-items-center">
                        <div class="col-md-6">
                            <div className='anotherHeader'>
                            <i class="las la-angle-left" onClick={() => history.push ('/systemConfig/viewrolePermission')}></i>
                            <h2 class="innerSubheadNormal">Role: Wallet Admin</h2>
                            {/* <span class="pendingStatus">Pending</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='viewcommonbox_'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='chartsOuter rolemaincontainer_'>
                            <div className='topheadpermit'>
                                <div className='row align-items-center'>
                                    <div className='col-md-8'>
                                        <div className='rolepermitTop'>
                                            <h5 className='subheadText'>Role Permission (Admin manager)</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-4 text-end'>
                                        <button class="rolesvaeBtn">
                                            <span className=''>Save</span>
                                        </button>
                                    </div>
                                </div>
                             </div>
                             <div className='middlebodycontain_'>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Orders Managment</h4>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="view"  value="" checked/>
                                        <label className='contentheadtext_ mb-3' for="view"> View</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="edit"  value="" />
                                        <label className='contentheadtext_ mb-3' for="edit"> Edit/Update</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="delete"  value="" />
                                        <label className='contentheadtext_ deletefade_ mb-3' for="delete"> Delete</label>
                                    </div>
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Invetory Managment</h4>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="view1"  value="" checked/>
                                        <label className='contentheadtext_ mb-3' for="view1"> View</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="edit1"  value="" />
                                        <label className='contentheadtext_ mb-3' for="edit1"> Edit/Update</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="add"  value="" />
                                        <label className='contentheadtext_ mb-3' for="add">Add Inventory</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="adjust"  value="" />
                                        <label className='contentheadtext_ mb-3' for="adjust">Adjust Inventory</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="delete1"  value="" />
                                        <label className='contentheadtext_ deletefade_ mb-3' for="delete1"> Delete</label>
                                    </div>
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Sales Management</h4>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="view2"  value="" checked/>
                                        <label className='contentheadtext_ mb-3' for="view2"> View</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="edit2"  value="" />
                                        <label className='contentheadtext_ mb-3' for="edit2"> Edit/Update</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="delete2"  value="" />
                                        <label className='contentheadtext_ deletefade_ mb-3' for="delete2"> Delete</label>
                                    </div>
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Wallet Management</h4>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="view3"  value="" checked/>
                                        <label className='contentheadtext_ mb-3' for="view3"> View</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="edit3"  value="" />
                                        <label className='contentheadtext_ mb-3' for="edit3"> Edit/Update</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="payment"  value="" />
                                        <label className='contentheadtext_ mb-3' for="payment">Payment Verifications</label>
                                    </div>
                                    <div className='rolecheckbox_'>
                                         <input className="me-2"type="checkbox" id="delete3"  value="" />
                                        <label className='contentheadtext_ deletefade_ mb-3' for="delete3"> Delete</label>
                                    </div>
                                </div>
                             </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='chartsOuter rolemaincontainer_'>
                                <div className='topheadpermit'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-7'>
                                                <div className='rolepermitTop'>
                                                    <h5 className='subheadText'>Assigned Users</h5>
                                                </div>
                                            </div>
                                            <div className='col-md-5 text-end'>
                                                <button class="valuesTimeswallet active">
                                                    <span>Add user</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                     <div className='middlebodycontain_'>
                                         <div className='userInformation_'>
                                             <div className='d-flex align-items-center'>
                                              <img className='userInnerImg' src={user} />
                                                <div className='ms-2'>
                                                  <span className='textParagh '>Teri J Henderson</span>
                                                 </div>
                                             </div>
                                             <div className='d-flex align-items-center'>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked/>
                                                    <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='userInformation_ deletefade_'>
                                             <div className='d-flex align-items-center'>
                                              <img className='userInnerImg' src={user} />
                                                <div className='ms-2'>
                                                  <span className='textParagh '>Teri J Henderson</span>
                                                 </div>
                                             </div>
                                             <div className='d-flex align-items-center'>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                                    <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default RolepermissionEdit