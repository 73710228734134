import React from 'react'
import { downlight } from '../../../../utilities/images';
import CustomLoader from '../../../components/shared/CustomLoader';
import HeaderStatistics from './HeaderStatistics';
import ChartArea from './ChartArea';
import { Link } from 'react-router-dom';
const Wallet = () => {
    document.title = "Wallet";
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };
    const options1 = {
        responsive: true,
        plugins: {
            legend: {
                display: true
            }
        }
    };
    const lineData = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
            {
                label: 'sales',
                data: [12, 56, 78, 10, 21, 11, 10],
                borderColor: '#1FB3FF',
                backgroundColor: '#1FB3FF',
            }
        ],
    };
    const tokenData = {
        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
        datasets: [
          {
            fill: true,
            label: 'JBR to Fiat',
            data: [42,20,54,35,44,77,66],
            borderColor: '#275AFF',
            backgroundColor: '#1675e063',
          },
          {
            fill: true,
            label: 'Fiat to JBR',
            data: [36,34,67,45,76,64,87],
            borderColor: '#FB466C',
            backgroundColor: '#e09fffab',
          },
        ],
      };
      const barData = {
        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
        datasets: [
          {
            label: 'Token Trades',
            data: [42,20,54,35,44,77,66],
            backgroundColor: '#275AFF',
          },
          {
            label: 'Sales Commision',
            data: [36,34,67,45,76,64,87],
            backgroundColor: '#1FB3FF',
          },
        ],
      };

    return (
        <>
            {false && (
                <CustomLoader />
            )}
            <div className='wallet'>
                <div className='row mb-3'>
                    <div className='col-md-12 text-end'>
                        <div className="dropdown weekDropdown">
                            <button className="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                This week <img src={downlight} alt="img" />
                            </button>
                            <ul className="dropdown-menu weekDropdownMenus">
                                <li><Link to="#" className="dropdown-item">This week</Link></li>
                                <li><Link to="#" className="dropdown-item">Last Week</Link></li>
                                <li><Link to="#" className="dropdown-item">This Month</Link></li>
                                <li><Link to="#" className="dropdown-item">This Quarter </Link></li>
                                <li><Link to="#" className="dropdown-item">This Year</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Start PercentageContainer Section */}
                <div className='verificationTotal'>
                    <div className='row'>
                        <HeaderStatistics value="10,029" heading="Total Applied" percentage="1.2" />
                        <HeaderStatistics value="9,960" heading="Total Verified" percentage="1.2" />
                        <HeaderStatistics value="163" heading="New Applications" percentage="1.2" />
                        <HeaderStatistics value="3" heading="Application denied" percentage="1.2" />
                    </div>
                </div>
                {/* End PercentageContainer Section */}
                <div className='salesTrend'>
                    <div className='row'>
                        <ChartArea dataId="1" classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3" header="Sales Trends" options={options} data={lineData} chartType="Line" />
                        <ChartArea classes="col-sm-6 col-md-6" header="Token Trades analysis" options={options1} data={tokenData} chartType="Line" />
                        <ChartArea classes="col-sm-6 col-md-6" header="Fees earning analysis" options={options1} data={barData} chartType="Bar" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wallet