import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { exportImg, imgGallery } from '../../../../utilities/images'

const AddnewUser = () => {
    const history = useHistory()
  return (
    <div className='addEmployeeModal commonBody'>
        <form className='commonbody_ horizontalForms'>
           <div className='row'>
           <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Roll</label>
                    <select className='w-100 w-100 selectbox_ '>
                        <option selected>Wallet Admin</option>
                        <option>Wallet Admin</option>
                        <option>Wallet Admin</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Employee  id</label>
                    <select className='w-100 selectbox_'>
                        <option selected>Type id</option>
                        <option>Wallet Admin</option>
                        <option>Wallet Admin</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>User name</label>
                    <input type='text' className='customform-control' placeholder='User name' value='' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Email address</label>
                    <input type='email' className='customform-control' placeholder='Email address' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>Phone number</label>
                    <input type='number' className='customform-control' placeholder='+1' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' for='amount'>One time Password</label>
                    <input type='password' className='customform-control' placeholder='Password' value='dd / mm/ yyyy' />
                </div>
                <div className='footerSec mt-4'>
                    <button to='' className='secoundaryBtn me-4'>Cancel</button>
                    <button to='' className='submitBtn'>Invite user</button>
                </div>
           </div>
        </form>
    </div>
  )
}

export default AddnewUser