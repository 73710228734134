import React from 'react'
import { downlight, graphsImg, shareMap } from '../../../utilities/images'
// import { Chart } from "react-google-charts";

const UserOverview = () => {
    const salesOptions = {
        // title: "Sales Trends",
        isStacked: true,
        height: 300,
        width: 700,
        series: [{ "color": "#275AFF" }],
        curveType: "function",
        legend: { position: "top", maxLines: 7 },
        vAxis: { minValue: 0 },
    };

    const salesData = [
        ["", ''],
        ['Sunday', 42],
        ['Monday', 20],
        ['Tuesday', 54],
        ['Wednesday', 35],
        ['Thursday', 44],
        ['Friday', 77],
        ['Saturday', 66]
    ];

    const tokenOptions = {
        // title: "Token Trades analysis",
        // isStacked: true,
        height: 400,
        width: 800,
        series: [{ "color": "#6C63F0" }, { "color": "#FB466C" }],
        intervals: { "style": "area", "color": "#D49464" },
        curveType: "function",
        legend: { position: "top", maxLines: 7 },
        vAxis: { minValue: 0 },
    };

    const tokenData = [
        ["", "Active Users", 'Inactive Users'],
        ['Mon', 42, 36],
        ['Tue', 20, 34],
        ['Wed', 54, 67],
        ['Thu', 35, 45],
        ['Fri', 44, 76],
        ['Sat', 77, 64],
        ['Sun', 66, 87]
    ];

    return (
        <div className='userOverview'>
            <div className='verificationTotal'>
                <div className='row'>
                    <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                        <div className='percentageContainer'>
                            <div className='percentageValues'>
                                <h3 className='heading_ '>10,029</h3>
                                <p className='textInner'>Total Applied</p>
                            </div>
                            <div className='percentageRating'>
                                <img src={shareMap} className="" alt='ShareMap' />
                                <p className='percentageRate'>1.2%</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                        <div className='percentageContainer'>
                            <div className='percentageValues'>
                                <h3 className='heading_ '>9,960</h3>
                                <p className='textInner'>Total Verified</p>
                            </div>
                            <div className='percentageRating'>
                                <img src={shareMap} className="" alt='ShareMap' />
                                <p className='percentageRate'>1.2%</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                        <div className='percentageContainer'>
                            <div className='percentageValues'>
                                <h3 className='heading_ '>163</h3>
                                <p className='textInner'>New Applications</p>
                            </div>
                            <div className='percentageRating'>
                                <img src={shareMap} className="" alt='ShareMap' />
                                <p className='percentageRate'>1.2%</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                        <div className='percentageContainer'>
                            <div className='percentageValues'>
                                <h3 className='heading_ '>3</h3>
                                <p className='textInner'>Application denied</p>
                            </div>
                            <div className='percentageRating'>
                                <img src={shareMap} className="" alt='ShareMap' />
                                <p className='percentageRate'>1.2%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='newUsers mt-4 mb-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='chartsOuter'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 class="textInnerHead mt-3">New Users</h3>
                                <div class="dropdown weekDropdown">
                                    <button class="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        This week <img src={downlight} alt="img" />
                                    </button>
                                    <ul class="dropdown-menu weekDropdownMenus">
                                        <li><a class="dropdown-item">This week</a></li>
                                        <li><a class="dropdown-item">Last Week</a></li>
                                        <li><a class="dropdown-item">This Month</a></li>
                                        <li><a class="dropdown-item">This Quarter </a></li>
                                        <li><a class="dropdown-item">This Year</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='row mt-4 mb-4 align-items-center'>
                                <div className='col-md-9'>
                                    {/* <Chart
                                        chartType="LineChart"
                                        data={salesData}
                                        options={salesOptions}
                                        legendToggle
                                    /> */}
                                    <img src={graphsImg} alt="Graph Image" className='img-fluid'/>
                                </div>
                                <div className='col-md-3'>
                                    <div className='tradesContainer'>
                                        <div className='tradesMain'>
                                            <h4 className='innerHeadingSky_'>290</h4>
                                            <p className='textinner_'>Individual</p>
                                        </div>
                                        <div className='tradesMain'>
                                            <h4 className='innerHeadingSky_'>33</h4>
                                            <p className='textinner_'>Retailers</p>
                                        </div>
                                        <div className='tradesMain'>
                                            <h4 className='innerHeadingSky_'>18</h4>
                                            <p className='textinner_'>Distro</p>
                                        </div>
                                        <div className='tradesMain'>
                                            <h4 className='innerHeadingSky_'>13</h4>
                                            <p className='textinner_'>Manufactures</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='totalUsers mt-4 mb-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='chartsOuter'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h3 class="textInnerHead mt-3">Total Users</h3>
                                    <h2 className='heading_ mt-3'>56,248</h2>
                                </div>
                                <div class="dropdown weekDropdown">
                                    <button class="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        This week <img src={downlight} alt="img" />
                                    </button>
                                    <ul class="dropdown-menu weekDropdownMenus">
                                        <li><a class="dropdown-item">This week</a></li>
                                        <li><a class="dropdown-item">Last Week</a></li>
                                        <li><a class="dropdown-item">This Month</a></li>
                                        <li><a class="dropdown-item">This Quarter </a></li>
                                        <li><a class="dropdown-item">This Year</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='row mt-4 mb-4 align-items-center'>
                                <div className='col-md-3'>
                                    <div className='tradesContainer'>
                                        <div className='tradesMain'>
                                            <h4 className='innerHeadingSky_'>290</h4>
                                            <p className='textinner_'>Active Users</p>
                                        </div>
                                        <div className='tradesMain'>
                                            <h4 className='inactiveHeading_'>33</h4>
                                            <p className='textinner_'>Inactive Users</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    {/* <Chart
                                        chartType="AreaChart"
                                        data={tokenData}
                                        options={tokenOptions}
                                        legendToggle
                                    /> */}
                                    <img src={graphsImg} alt="Graph Image" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserOverview