import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { brandLogo, disbursement, feesSetting, fiatTocken, logoutImg, home, purchase, setting, support, tockenUsage, userList } from '../../../utilities/images';
import { logout } from '../../containers/auth/authSlice';
const Sidebar = (props) => {
    const [activeData, setActiveData] = useState("")
    const location = useLocation();
    const pathName = location.pathname;

    const [activeMenu, setActiveMenu] = useState("Dashboard")
    const history = useHistory();
    const dispatch = useDispatch()

    const closeActions = () => {
        document.body.setAttribute("style", "overflow:auto");
    }

    const closeSide = () => {
        props.closeNav();
        document.body.setAttribute("style", "overflow:auto");
    }

    const userLogout = async () => {
        await dispatch(logout())
        window.localStorage.clear()
        await history.push('/')
    }
    return (
        <aside className={`vertical-tab ${props?.nav?.open_nav ? 'slideOpen' : ''}`} id="myNav">
            <Link to="/dashboard" className="brandLogo">
                <img src={brandLogo} className="img-fluid" alt="" />
            </Link>

            <Link className="closebtn2"
                to="#"
                onClick={() => {
                    closeSide();
                    closeActions()
                }} ><i className="las la-times"></i></Link>
            <ul className="sidebarMenus navbar_overlay_content_">
                <div className='sidebarStaticMenus'>
                    <li className="sidebarItems dropdown">
                        <Link to="#" onClick={() => activeMenu === "Dashboard" ? setActiveMenu("") : setActiveMenu("Dashboard")} className={activeMenu === "Dashboard" ? "sidebarLinks dropdown-toggle show" : "sidebarLinks dropdown-toggle"} id="sidebarDropdown">
                            <img src={home} className="img-fluid showImg" alt="" />
                            <span className='menusTxt'>Dashboard</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={activeMenu === "Dashboard" ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/wallet"
                                    className={pathName == '/wallet' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Wallet
                                </Link>
                            </li>
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>

                    <li className={activeData == 'googleAnalytics' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/googleAnalytics" className="sidebarLinks" onClick={() => setActiveData("googleAnalytics")}>
                            <img src={feesSetting} className="img-fluid" alt="" />
                            <span className='menusTxt'>Google Analytics</span>
                        </Link>
                    </li>

                    <li className={activeData == 'overview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("overview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={home} className="img-fluid showImg" alt="" />
                            <span className='menusTxt'>Verification Hub</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/overview"
                                    className={pathName === '/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/newApplication"
                                    className={pathName === '/newApplication' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    New Applications
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/verification/verified"
                                    className={pathName === '/verification/verified' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Verified Applications
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/verification/deniedApplication"
                                    className={pathName === '/verification/deniedApplication' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Denied Applications
                                </Link>
                            </li>
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>

                    <li className={activeData == 'tokenUsage' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("tokenUsage")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={tockenUsage} className="img-fluid showImg" alt="" />
                            {/* <img src={shippingOrderDark} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>JBR Token Usage</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/tokenUsage/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/tokenUsage/overview"
                                    className={pathName == '/tokenUsage/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/tokenUsage/tokenAdded"
                                    className={pathName == '/tokenUsage/tokenAdded' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Token Added
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/tokenUsage/tokenSent"
                                    className={pathName == '/tokenUsage/tokenSent' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Token Sent
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/tokenUsage/tokenPayment"
                                    className={pathName == 'tokenUsage/tokenPayment' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Token Payment
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/tokenUsage/tokenRequested"
                                    className={pathName == '/tokenUsage/tokenRequested' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Token Requested
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/tokenUsage/tokenRefunded"
                                    className={pathName == '/tokenUsage/tokenRefunded' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Token Refunded
                                </Link>
                            </li>
                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    
                    <li className={activeData == 'fiatTocken/overview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("fiatTocken/overview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={home} className="img-fluid showImg" alt="" />
                            <span className='menusTxt'>Fiat to Token</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/fiatTocken/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/fiatTocken/overview"
                                    className={pathName == '/fiatTocken/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/fiatTocken/tokenTransections"
                                    className={pathName == '/fiatTocken/tokenTransections' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Token Transections
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/fiatTocken/referralTokenTransections"
                                    className={pathName == '/fiatTocken/referralTokenTransections' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Referral Token Transections
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/fiatoverview"
                                    className={pathName == '/fiatoverview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    All Transections
                                </Link>
                            </li>

                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    <li className={activeData == 'tokenFiat/label' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("tokenFiat/label")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={fiatTocken} className="img-fluid showImg" alt="" />
                            {/* <img src={fiatTockenActive} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>JBR Token Fiat</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        {/* Start SidebarInnerMenus */}
                        <ul className={pathName === '/tokenFiat/label' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/tokenFiat/label"
                                    className={pathName == '/tokenFiat/label' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Label
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/tokenFiat/instantWithdrawal"
                                    className={pathName == '/tokenFiat/instantWithdrawal' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Instant Withdrawal
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/tokenFiat/sameWithdrawal"
                                    className={pathName == '/tokenFiat/sameWithdrawal' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Same day Withdrawal
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/tokenFiat/standardWithdrawal"
                                    className={pathName == '/tokenFiat/standardWithdrawal' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Standard Withdrawal
                                </Link>
                            </li>

                        </ul>
                        {/* End SidebarInnerMenus */}
                    </li>
                    <li className={activeData == 'userList/overview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("userList/overview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={userList} className="img-fluid showImg" alt="" />
                            {/* <img src={userListActive} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>User List</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        <ul className={pathName === '/userList/overview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/userList/overview"
                                    className={pathName == '/userList/overview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/userList/activeUsers"
                                    className={pathName == '/userList/activeUsers' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Active Users
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/userList/inactiveUsers"
                                    className={pathName == '/userList/inactiveUsers' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Inactive Users
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/userList/disableUsers"
                                    className={pathName == '/userList/disableUsers' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Disable Users
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeData == 'tray' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/drawerCash" className="sidebarLinks" onClick={() => setActiveData("tray")}>
                            <img src={purchase} className="img-fluid showImg" alt="" />
                            {/* <img src={trayDark} className="img-fluid hoverImg" alt="" /> */}
                            <span className='menusTxt'>Purchases History</span>
                        </Link>
                    </li>
                    <li className={activeData == 'disbursement/disbursementOverview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("disbursement/disbursementOverview")} data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={disbursement} className="img-fluid" alt="" />
                            <span className='menusTxt'>Disbursement</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        <ul className={pathName === 'disbursement/disbursementOverview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disbursementOverview"
                                    className={pathName == '/disbursement/disbursementOverview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disburseOnhold"
                                    className={pathName == '/disbursement/disburseOnhold' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    On Hold
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/disbursement/disbursementPaid"
                                    className={pathName == '/disbursement/disbursementPaid' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Paid
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeData == 'reward' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/rewards" className="sidebarLinks" onClick={() => setActiveData("reward")}>
                            <img src={feesSetting} className="img-fluid" alt="" />
                            <span className='menusTxt'>Fees Settings</span>
                        </Link>
                    </li>
                    <li className={activeData == 'systemConfig/systemEmployee' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("systemConfig/systemEmployee")}
                            data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={setting} className="img-fluid" alt="" />
                            <span className='menusTxt'>System Config</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        <ul className={pathName === 'systemConfig/systemEmployee' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/systemEmployee"
                                    className={pathName == '/systemConfig/systemEmployee' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Employee
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/userRoles"
                                    className={pathName == '/systemConfig/userRoles' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    User Roles
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/bankandcardList"
                                    className={pathName == '/systemConfig/bankandcardList' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    List of Banks & Cards
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/thirdpartyApi"
                                    className={pathName == '/systemConfig/thirdpartyApi' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    3rd party API
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/systemSetting"
                                    className={pathName == '/systemConfig/systemSetting' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    System Settings
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/termsandCondition"
                                    className={pathName == '/systemConfig/termsandCondition' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Terms and Condition
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/systemConfig/systemFaq"
                                    className={pathName == '/systemConfig/systemFaq' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={activeData == 'supportOverview' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#" className="sidebarLinks dropdown-toggle" onClick={() => setActiveData("supportOverview")}
                            data-bs-toggle="dropdown" aria-expanded="false" id="sidebarDropdown">
                            <img src={support} className="img-fluid" alt="" />
                            <span className='menusTxt'>Support</span>
                            <i className="fa-solid fa-caret-down caretIcon"></i>
                        </Link>
                        <ul className={pathName === 'support/supportOverview' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/support/supportOverview"
                                    className={pathName == '/support/supportOverview' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/support/suportTicket"
                                    className={pathName == '/support/suportTicket' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Tickets
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/support/supportCalls"
                                    className={pathName == '/support/supportCalls' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Calls
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/support/supportChats"
                                    className={pathName == '/support/supportChats' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Chats
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/support/supportEmail"
                                    className={pathName == '/support/supportEmail' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Emails
                                </Link>
                            </li>
                        </ul>
                    </li>
                </div>
                <div className='sidbarfixedMenus'>
                    <li className="sidebarItems" onClick={() => { userLogout() }}>
                        <Link to="#" className="sidebarLinks" onClick={() => setActiveData("power")}>
                            <img src={logoutImg} className="img-fluid" alt="" />
                            <span className='menusTxt'>Logout</span>
                        </Link>
                    </li>
                </div>
            </ul>
            {/* <ul className='sidebarMenus navbar_overlay_content'>
            </ul> */}
            {/* <div  className="main-sidebar" id="myNav" >
                <div className="vertical_top" id="vertical_top">
                    <a className="closebtn2"
                        onClick={() => {
                            closeSide();
                            closeActions()
                        }}
                    ><i className="las la-times"></i></a>
                    <span className="expanded_tab"><i className="fas fa-arrows-alt-h"></i></span>
                </div>
            </div> */}
        </aside>



    )
}
const mapStateToProps = state => ({
    auth: state.auth,
    nav: state.nav
});
const mapDispatchToProps = dispatch => ({
    // logout: () =>  dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
