import React from 'react'

const SystemFaq = () => {
  return (
    <div className='systemFaq'>
      <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-6">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>FAQ</span> </h2>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default SystemFaq