import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { bankImg1, bankImg2, bankImg3 } from '../../../../utilities/images';
import AddbankaccountModal from './addbankaccountModal';
import AccountdetailsModal from './accountdetailsModal';
import CustomModal from '../../../components/shared/CustomModal';

const BankandcardList = () => {
  const history = useHistory()
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  return (
    <>
    <div className='bankCardList'>
      <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-6">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>List of Banks & cards</span> </h2>
              </div>
              <div class="col-md-6 text-end">
                <div className='customDropdown'>
                  <h2 className="backgroundFillbtn" to="" id="exportDropdown" data-bs-toggle="dropdown" aria-expanded="false"
                  onClick={() => {
                    setModalDetail({ show: true, flag: "Addbankaccount" });
                    setKey(Math.random());
                  }}
                  >Add</h2>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-12'>
          <Link to="#" className='valuesTimeswallet active'>Bank</Link>
          <Link to="#" className='valuesTimeswallet' onClick={() => history.push ('/systemConfig/cardList') }>Cards</Link>
        </div>
      </div>
      <div className='chartsOuter'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='cardListContainer'>
              <div className='d-flex justify-content-between'>
                  <p className='textinner_'>Bank Name</p>
                  <i class="fal fa-ellipsis-v icon_" aria-hidden="true" onClick={() => {
                    setModalDetail({ show: true, flag: "Accountdetails" });
                    setKey(Math.random());
                  }}></i>
              </div>
              <div className=''>
                <img src={bankImg1} alt='BankImg'/>
                <span className='textInnerHead ms-2'>Bank of America</span>
                <p className='textinner_ mt-2'>Account Number: 56...........1236</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='cardListContainer'>
              <div className='d-flex justify-content-between'>
                  <p className='textinner_'>Bank Name</p>
                  <i class="fal fa-ellipsis-v icon_" aria-hidden="true"></i>
              </div>
              <div className=''>
                <img src={bankImg2} alt='BankImg'/>
                <span className='textInnerHead ms-2'>Bank of th West</span>
                <p className='textinner_ mt-2'>Account Number: 56...........1236</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='cardListContainer'>
              <div className='d-flex justify-content-between'>
                  <p className='textinner_'>Bank Name</p>
                  <i class="fal fa-ellipsis-v icon_" aria-hidden="true"></i>
              </div>
              <div className=''>
                <img src={bankImg3} alt='BankImg'/>
                <span className='textInnerHead ms-2'>Bank of America</span>
                <p className='textinner_ mt-2'>Account Number: 56...........1236</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CustomModal
    key={key}
    show={modalDetail.show}
    backdrop="static"
    showCloseBtn={false}
    isRightSideModal={false}
    mediumWidth={false}
    className={modalDetail.flag === "Addbankaccount" ? "AddbankaccountModal" : "Accountdetails" ? "AccountdetailsModal" : ""}
    ids={modalDetail.flag === "Addbankaccount" ? "AddbankaccountModal" : "Accountdetails" ? "AccountdetailsModal" : ""}
    // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
    child={
        modalDetail.flag === "Addbankaccount" ?(
            <AddbankaccountModal close={() => handleOnCloseModal()} />
            
        ) :
        modalDetail.flag === "Accountdetails" ?(
          <AccountdetailsModal close={() => handleOnCloseModal()} />
          
      ) : (
            ""
            
        )
        
    }
    header={
        <div className='modalHeader_'>
            <div className="common_">
                <h2 className="modalHeading">
                Bank Account Details
                </h2>
                <button class="closeButton">
                    <i class="las la-times" onClick={() => handleOnCloseModal()}></i>
                </button>
            </div>
        </div>
    }
    onCloseModal={() => handleOnCloseModal()}
/>
</>
  )
}

export default BankandcardList