import moment from 'moment-timezone';
import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { calendar, shield, user } from '../../../../utilities/images';
import products from "../../../db/category.json"
import CustomModal from '../../../components/shared/CustomModal';
import ReferraltransactionModal from '../tokenTransaction/tokentransactionModal';

const ReferralTokenTransections = () => {
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const history = useHistory()
    const columns1 = [
      {
        name: '#',
        selector: row => row.id,
        width: "50px" 
      },
      {
        name: "Name",
        selector: row => {
        return (
              <>
                <div className='d-flex align-items-center'>
                  <img className='userInnerImg' src={user} />
                  <div className='ms-2'>
                    <span className='contentSubtext_ '>{row.name}</span>
                    <p className='mb-0'>
                      <img src={shield} className='img-fluid me-2'/>
                      <span className='textInner'>260 101 480 0083 </span>
                    </p>
                  </div>
                </div>
              </>
            );
        },
        width: "300px", 
      },
      { name: 'Date',
      selector: row => {
        return (
              <>
                <div className='ms-2'>
                    <span className='contentSubtext_'>{row.date}</span>
                    <p className='textInner mt-2'>9:00:23a</p>
                </div>
              </>
            );
        },
        width: "220px",
      },
      {
        selector: row => row.value,
        name: 'Transection id'
      },
      {
        selector: row => row.amount,
        name: 'Fiat Amount'
      },
      {
        selector: row => row.amount2,
        name: 'Token Amount'
      },
      {
        selector: row => row.types,
        name: 'Type'
      },
      {
        selector: row => row.fees,
        name: 'Fees'
      },
      {
        name: 'Status',
        selector: row => {
          return (
                <>
                  <div className='ms-2'>
                      <Link to='' className='innerBtn_'>Completed</Link>
                      <button className='nextAction' type="text" onClick={() => {
                    setModalDetail({ show: true, flag: "ReferralTransaction" });
                    setKey(Math.random());
                  }}><i class="fal fa-chevron-right ms-3" ></i></button> 
                  </div>
                </>
              );
          },
          width: "170px"
      }
    ];
  
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='referralToken'>
        <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-6">
                <h2 class="mainHeading">Referral Token Transections</h2>
              </div>
              <div class="col-md-6 text-end">
                <div className='dropdown customDropdown'>
                  <Link className="backgroundFillbtn dropdown-toggle" to="/verification/applicationDetailkyc" id="exportDropdown" data-bs-toggle="dropdown" aria-expanded="false">Export <i class="fal fa-chevron-down ms-3" aria-hidden="true"></i></Link>
                  <ul className='dropdown-menu customDropdownMenus' aria-labelledby='exportDropdown'>
                      <li className='dropdown-item customDropdownLinks'>
                        <Link to='' className='customDropdownLinks'>Export to CSV</Link>
                      </li>
                      <li className='dropdown-item customDropdownLinks'>
                        <Link to='' className='customDropdownLinks'>Export to Excel </Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters'>
                <div className="calenderIcon">
                  <img src={calendar} alt="calender" />
                  <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                  dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
                </div>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Country
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      State
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Id type
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
          </div>
        </div>
        <DataTable
            pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
        />
              <CustomModal
    key={key}
    show={modalDetail.show}
    backdrop="static"
    showCloseBtn={false}
    isRightSideModal={false}
    mediumWidth={false}
    className={modalDetail.flag === "ReferralTransaction" ? "ReferraltransactionModal" : ""}
    ids={modalDetail.flag === "ReferralTransaction" ? "ReferraltransactionModal" : ""}
    // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
    header={
      <div className='modalHeader_'>
        <div className="common_">
          <h2 className="modalHeading">
            Referral Token Transections
          </h2>
          <button class="closeButton">
            <i class="las la-times" onClick={() => handleOnCloseModal()}></i>
          </button>
        </div>
      </div>
    }
    child={
      modalDetail.flag === "ReferralTransaction" ? (
        <ReferraltransactionModal close={() => handleOnCloseModal()} />
      ) : (
        ""
      )
    }
    onCloseModal={() => handleOnCloseModal()}
  />
    </div>
  )
}

export default ReferralTokenTransections