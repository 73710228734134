import moment from 'moment-timezone'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { calendar, merchant1, merchantActive, pinterst, shield, shieldRegular, user } from '../../../utilities/images'
import products from "../../db/category.json"
import DataTable from 'react-data-table-component';

const DisbursementPaid = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        {
          name: "User",
          selector: row => {
          return (
                <>
                  <div className='d-flex align-items-center'>
                    <img className='userInnerImg' src={pinterst} />
                    <div className='ms-2'>
                      <span className='contentSubtext_ '>Penn Fruit</span>
                      <p className='mb-0'>
                        <img src={shield} className='img-fluid me-2 custImg'/>
                        <span className='contentSmallTxt'>260 101 480 0083</span>
                      </p>
                    </div>
                  </div>
                </>
              );
          },
          width: "200px", 
        },
        { name: 'Date',
        selector: row => {
          return (
                <>
                  <div className='ms-2 text-center d-flex justify-content-center'>
                      <div>
                        <span className='textParagh'>{row.date}</span>
                        <p className='subtextSmall_ mt-1'>9:00:23a</p>
                      </div>
                  </div>
                </>
              );
          },
          width: "220px",
        },
        {
          selector: row => {
            return (
                <>
                    <span className='textParagh'><b>2882249666</b></span>
                </>
            )
          },
          width: "200px",
          name: 'Transection Id'
        },
        {
          selector: row =>
          {
            return (
                <>
                    <span className='textParagh'><b>JBR 100</b></span>
                </>
            )
          },
          name: 'Hold Amount'
        },
        {
          name: 'Status',
          selector: row => {
            return (
                  <>
                    <div className='ms-2 tableBtn__ text-end'>
                        <Link to='' className='innerBtn_'>Paid</Link>
                        <i class="fal fa-chevron-right ms-4"></i>
                    </div>
                  </>
                );
            },
            width: "150px"
        }
    ];
    
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='inactiveUser'>
        <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-12">
                <h2 class="mainHeading">Paid</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='commonTables mt-3 mb-3'>
            <div className='tableFilters'>
                <div className="calenderIcon">
                    <img src={calendar} alt="calender" />
                    <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                    dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
                    </div>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        Country
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        State
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        Id type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
            </div>
        </div>
        <DataTable
            pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
        />
    </div>
  )
}

export default DisbursementPaid