import React from 'react'

const SystemSetting = () => {
  return (
    <div className='systemSetting'>
       <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-6">
                <h2 class="mainHeading">System Config / <span className='innerSubtext'>System Settings</span> </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='notificationContent_'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='notificationLeft_'>
                <ul>
                  <li className='notificationList active'><span className='semiboldHeading__'>Notification</span> <i class="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'>Notification</span> <i class="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'>Notification</span> <i class="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'>Notification</span> <i class="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'>Notification</span> <i class="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'>Notification</span> <i class="fal fa-chevron-right" aria-hidden="true"></i></li>

                </ul>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='notificationRight_'>
               <h5 className='deatilsHead'>Notification</h5>
               <div className='notificationInfo_'>
                <h4 className='notifyHead'>App Notification</h4>
                  <ul className='notifictionContent'>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked/>
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                    </li>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                      </li>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                    </li>
                  </ul>
               </div>
               <div className='notificationInfo_'>
                <h4 className='notifyHead'>SMS Notification</h4>
                  <ul className='notifictionContent'>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked/>
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                    </li>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                      </li>
                  </ul>
               </div>
               <div className='notificationInfo_'>
                <h4 className='notifyHead'>Email Notification</h4>
                  <ul className='notifictionContent'>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked/>
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                    </li>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span> 
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                      </li>
                  </ul>
               </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default SystemSetting