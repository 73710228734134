import React from 'react'
import { useHistory } from 'react-router-dom'
import { detailuser, phoneBlue, emailBlue, locationBlue} from '../../../../utilities/images'
const ResolvedcallDetail = () => {
    const history = useHistory()
  return (
    <>
    <div className='suportticketDetails'>
        <div className='topHeader_'>
            <div class="topStickyHeader_ mb-3">
                <div class="row  align-items-center">
                    <div class="col-md-12">
                        <div className='anotherHeader'>
                        <i class="las la-angle-left" onClick={() => { history.push("/support/resolvedCalls") }}></i>
                        <h2 class="innerSubheadNormal">Ticket id #25649895</h2>
                        <span class="completeStatus">Resolved</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='suportticketDetails_'>
        <div className='row'>
            <div className='col-lg-9'>
                <div className='informationcontentInner whiteInnerbg'>
                    <div className='callsrecodDetail'>
                        <h6 className='callDate_'>Today | April 20, 2022</h6>
                    </div> 
                </div>
            </div>
            <div className='col-lg-3'>
                <div className='detailsTicketRight_'>
                    <div className='detailsTicketinfo_ mb-2'>
                            <img src={detailuser} />
                            <div className='nameDetail'>
                                <h5 className='contentSubtextbold'>Florecilla Jonny</h5>
                                <p className='tableHeadsmall'>Manufacturer</p>
                            </div>
                        </div>           
                        <div className="detailsTicketinfoInner_">
                            <p className='contentextLight'>
                            <img src={phoneBlue} /> 
                            <span>4703-515-1193</span> 
                            </p>
                            <p className='contentextLight'>
                            <img src={emailBlue} /> 
                            <span> vidal.webe2@gmail.com</span> 
                            </p>
                            <p className='contentextLight'>
                            <img src={locationBlue} /> 
                            <span> 4318 Daffodil Lane, Savage, Virginia(VA), 20763</span> 
                            </p>
                        </div>
                        <div className='totalTickets_ mt-3'>
                            <h5 className='subInnersubhead'>Total Tickets: 16</h5>
                            <div className='totalticketBottom'>
                                 <p className='smallHeading'>Solved: <strong>9</strong></p>
                                 <p className='smallHeading'>Cancelled: <strong>9</strong></p>
                            </div>
                            <div className='totalticketBottom'>
                                 <p className='smallHeading'>Open: <strong>9</strong></p>
                                 <p className='smallHeading'>Unrespond: <strong>9</strong></p>
                            </div>
                        </div>
                        </div>
                        <div className='asignAgent_ mt-3'>
                            <h5 className='agentHeading'>Assigned agent:</h5>
                            <div className='detailsTicketinfo_ mb-2'>
                                <img src={detailuser} />
                                <div className='nameDetail'>
                                    <h5 className='contentSubtextbold'>Satomi D.</h5>
                                    <p className='tableHeadsmall'>13 Jun, 2022  |   12:25p</p>
                                </div>
                            </div>
                       </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ResolvedcallDetail