import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { user, shield, shareMap, redmap } from '../../../utilities/images'
import products from "../../db/category.json"
const DisbursementOverview = () => {
  const history = useHistory()
  const columns1 = [
    {
      name: '#',
      selector: row => row.id,
      width: "50px" 
    },
    {
      name: "Name",
      selector: row => {
      return (
            <>
              <div className='d-flex align-items-center'>
                <img className='userInnerImg' src={user} />
                <div className='ms-2'>
                  <span className='contentSubtext_ '>{row.name}</span>
                  <p className='mb-0'>
                    <img src={shield} className='img-fluid me-2'/>
                    <span className='contentSmallTxt'>260 101 480 0083 </span>
                  </p>
                </div>
              </div>
            </>
          );
      },
      width: "200px", 
    },
    { name: 'Date',
    selector: row => {
      return (
            <>
              <div className='ms-2 text-center'>
                  <span className='textParagh'>{row.date}</span>
                  <p className='textInner mt-1'>9:00:23a</p>
              </div>
            </>
          );
      },
      width: "220px",
    },
    {
      selector: row => row.value,
      name: 'Transection id'
    },
    {
      selector: row => row.amount,
      name: 'Hold Amount'
    },
   
    {
      name: 'Status',
      selector: row => {
        return (
              <>
                <div className='ms-2 tableBtn__ dropdown'>
                    <Link className='inActiveBtn_ dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">On Hold
                    </Link>
                    <i class="fal fa-chevron-down ms-3"></i>
                    {/* <ul className='dropdown-menu' aria-aria-labelledby='dropdownMenuButton1'>
                      <li className=''>hello</li>
                    </ul> */}
                </div>
              </>
            );
        },
        width: "170px"
    }
  ];

  const handleRowClick = (e) => {
      // console.log("rowclicked", e)
      history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
  }
  return (
    <>
    <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-6">
                <h2 class="mainHeading">Disbursement </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='tokendUsage'>
        <div className='chartsOuter'>
            <div className='row align-items-center'>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-5'>
                    <h3 class="textInnerHead mt-3">Amount On Hold</h3>
                    <h2 className='mainBoldHeading'>$593,056</h2>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-7'>
                  {/* Start PercentageContainer Section */}
                  <div className='verificationTotal mt-4'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>$489,623</h3>
                                  <p className='textInner'>For Driver</p>
                              </div>
                              <div className='percentageRating'>
                                  <img src={shareMap} className="" alt='ShareMap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>$13,205</h3>
                                  <p className='textInner'>For Merchants</p>
                              </div>
                              <div className='percentageRating'>
                                  <img src={shareMap} className="" alt='ShareMap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>$93,148</h3>
                                  <p className='textInner'>For 3rd Party</p>
                              </div>
                              <div className='percentageRating'>
                                  <img src={shareMap} className="" alt='ShareMap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                            <div className='percentageContainer'>                          
                              <div className='percentageValues'>
                                  <h3 className='heading_ '>$782</h3>
                                  <p className='textInner'>For JOBR</p>
                              </div>
                              <div className='percentageRating redRating'>
                                  <img src={redmap} className="" alt='redmap' />
                                  <p className='percentageRate'>1.2%</p>
                              </div>
                            </div>
                        </div>
                  </div>
                  </div> 
                  {/* End PercentageContainer Section */}
                </div>
            </div>
        </div>
      </div>
      <section className='recentTransections mt-4'>
            <h3 className='textInnerHead mb-4'>Recent Activities</h3>
        <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
        </section>
        </>
  )
}

export default DisbursementOverview;