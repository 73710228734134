import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loginApi,
  forgetPassword,
  verifyResetPassword,
  resetPassword
} from "./authApi";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  forgetPassword: null,
  verifyResetPassword: null,
  resetPassword: null,
  status: "idle"
};

export const loginAsync = createAsyncThunk("auth/loginApi", async (data) => {
  const response = await loginApi(data);
  return response.data;
});
export const forgetPasswordAsync = createAsyncThunk("auth/forgetPassword", async (data) => {
  const response = await forgetPassword(data);
  return response.data;
});
export const verifyPasswordAsync = createAsyncThunk("auth/verifyResetPassword", async (data) => {
  const response = await verifyResetPassword(data);
  return response.data;
});
export const resetPasswordAsync = createAsyncThunk("auth/resetPassword", async (data) => {
  const response = await resetPassword(data);
  return response.data;
});


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      toast.success("Successfully Logout")
    },
  },

  extraReducers: {
    [loginAsync.pending](state) {
      state.status = "loading";
    },
    [loginAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.user = payload;
    },
    [loginAsync.rejected](state, { error }) {
      state.status = "idle";
      state.user = [];
    },
    [forgetPasswordAsync.pending](state) {
      state.status = "loading";
    },
    [forgetPasswordAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.forgetPassword = payload;
    },
    [forgetPasswordAsync.rejected](state, { error }) {
      state.status = "idle";
      state.forgetPassword = [];
    },
    [verifyPasswordAsync.pending](state) {
      state.status = "loading";
    },
    [verifyPasswordAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.verifyResetPassword = payload;
    },
    [verifyPasswordAsync.rejected](state, { error }) {
      state.status = "idle";
      state.verifyResetPassword = [];
    },
    [resetPasswordAsync.pending](state) {
      state.status = "loading";
    },
    [resetPasswordAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.resetPassword = payload;
    },
    [resetPasswordAsync.rejected](state, { error }) {
      state.status = "idle";
      state.resetPassword = [];
    },
  },
});

export const { logout } = authSlice.actions;
export const selectLoginAuth = (state) => state.auth.user;

export default authSlice.reducer;