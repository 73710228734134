import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {shareMap, calendar} from '../../../../utilities/images'
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Verified = () => {
    const history = useHistory();
    document.title = "VerifiedApplication";
const [enterprisesDate, setEnterprisesDate] = useState("")
  return (
    <>
   <div className='topHeader_'>
    <div className='topStickyHeader_ mb-3'>
            <div className='row  align-items-center'>
                <div className='col-md-6'>
                        <h2 className='mainHeading'>Verified Applications</h2>
                </div>
                <div className='col-md-6 text-end'>
                    <Link to="/verification/verifiedDetails" className='backgroundFillbtn'>Export</Link>
                </div>
            </div>
        </div>
   </div>
    <div className='verificationTotal'>
        <div className='row'>
            <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                <div className='percentageContainer'>                          
                <div className='percentageValues'>
                    <h3 className='heading_ '>163</h3>
                    <p className='textInner'>Total Verified</p>
                </div>
                <div className='percentageRating'>
                    <img src={shareMap} className="" alt='ShareMap' />
                    <p className='percentageRate'>1.2%</p>
                </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                <div className='percentageContainer'>                          
                <div className='percentageValues'>
                    <h3 className='heading_ '>59</h3>
                    <p className='textInner'>Verified Today</p>
                </div>
                <div className='percentageRating'>
                    <img src={shareMap} className="" alt='ShareMap' />
                    <p className='percentageRate'>1.2%</p>
                </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                <div className='percentageContainer'>                          
                <div className='percentageValues'>
                    <h3 className='heading_ '>1</h3>
                    <p className='textInner'>Country</p>
                </div>
                <div className='percentageRating'>
                    <img src={shareMap} className="" alt='ShareMap' />
                    <p className='percentageRate'>1.2%</p>
                </div>
                </div>
            </div>
            <div className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                <div className='percentageContainer'>                          
                <div className='percentageValues'>
                    <h3 className='heading_ '>13</h3>
                    <p className='textInner'>States</p>
                </div>
                <div className='percentageRating'>
                    <img src={shareMap} className="" alt='ShareMap' />
                    <p className='percentageRate'>1.2%</p>
                </div>
                </div>
            </div>
        </div>              
    </div>
    <div className='commonTables mt-3 mb-3'>
        <div className='tableFilters'>
             <div className="calenderIcon">
                <img src={calendar} alt="calender" />
                <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
                 </div>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Country
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                </select>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    State
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                </select>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Id type
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                </select>
        </div>
    </div>
  </>
  )
}

export default Verified;