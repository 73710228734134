import React from 'react';
import { Route } from 'react-router-dom';
import Authenticator from './authenticator';

import DashboardLayout from '../components/shared/DashboardLayout';

const PrivateRoute = ({ component: Component, allowed, ...rest }) => (
  <Route {...rest} render={props => (
    <Authenticator isAuthenticationRequired={true} {...props}>
      <DashboardLayout>
        <Component {...props}/>
      </DashboardLayout>
    </Authenticator>
  )} />
)

export default PrivateRoute;
