import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user } from '../../../../utilities/images';
import CustomModal from '../../../components/shared/CustomModal';
import SuportticketModal from './suportticketModal';
const ResolvedTicket = () => {

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        { name: 'Date',
        selector: row => {
          return (
                <>
                  <div className='ms-2'>
                      <span className='contentSubtext_'>{row.date}</span>
                      <p className='textInner mt-2'>9:00:23a</p>
                  </div>
                </>
              );
          },
          width: "220px",
        },
        {
          selector: row => row.value,
          name: 'Ticket id'
        },
        {
          selector: row => row.amount,
          name: 'Department'
        },
        {
          selector: row => row.amount2,
          name: 'Subject'
        },
        {
          selector: row => row.types,
          name: 'Responsible'
        },
        {
          name: 'Status',
          selector: row => {
            return (
                  <>
                    <div className='ms-2'>
                      <span className='personalinfoHead mt-21234'>Resoved | agent name</span>
                      <p className='tablesubHead mt-2'>Sep 22, 2022 | 9:30 am</p>
                  </div>
                  </>
                );
            },
            width: "170px"
        },
        {
          name: '',
          selector: row => {
            return (
                  <>
                  <i class="fal fa-chevron-right " onClick={() => { history.push("/support/resolvedticketDetail") }}></i> 
                  </>
                );
            },
            width: "50px",
        },
      ];
    
  
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='suportTicket'>
       <div className='topHeader_'>
          <div class="topStickyHeader_ mb-3">
            <div class="row  align-items-center">
              <div class="col-md-12">
                <h2 class="mainHeading">Support /<span className='deviceHead'> Tickets</span></h2>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
            <div className='row'>
                <div className='col-md-12'>
                    <button className='valuesTimeswallet'>New Tickets (95)</button>
                    <button className='valuesTimeswallet'>Pending Tickets (13)</button>
                    <button className='valuesTimeswallet active'>Resolved Tickets</button>
                </div>
            </div>
        </div>
        <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters'>
                <div className="calenderIcon">
                  <img src={calendar} alt="calender" />
                  <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                  dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
                </div>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Country
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      State
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
                  <select className="cateSelectdFilter" required>
                      <option value="" selected>
                      Id type
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                  </select>
          </div>
        </div>
        <DataTable
            pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
          />
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "SuportticketModal" ? "suportticketModal" : ""}
          ids={modalDetail.flag === "SuportticketModal" ? "suportticketModal" : ""}
          // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
          child={
            modalDetail.flag === "SuportticketModal" ? (
              <SuportticketModal close={() => handleOnCloseModal()} />
            ) : (
              ""
            )
          }
          header={
            <div className='modalHeader_'>
              <div className="common_">
                  <h2 className="mainHeading">
                  Assign Agent
                  </h2>
                  <button class="closeButton">
                    <i class="las la-times" onClick={() => handleOnCloseModal()}></i>
                  </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
    </div>
  )
}

export default ResolvedTicket